import React from 'react'

import {
  Button,
  Typography,
  Paper,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material'
import { SuggestedQuestionsProps } from '../popups/SuggestedQuestions'
import { FieldProps } from 'formik'
import { ButtonSize } from '../../types'
import { convertButtonSize } from '../../util/button'
import { InputWrapper } from './InputWrapper'

interface ToggleInformation {
  /**
   * ID of the toggle to be stored in the form
   */
  id: string

  /**
   * Text of the toggle to display
   */
  text: string
}

interface ToggleProps extends FieldProps {
  /**
   * Size of 'ask' buttons
   */
  buttonSize?: ButtonSize

  /**
   * Information for each of the toggles to display
   */
  toggleOptions: ToggleInformation[]

  /**
   * Label title for the input
   */
  label: React.ReactNode

  /**
   * Styles to apply to the container around the toggle buttons
   */
  toggleWrapperStyles?: SxProps<Theme>

  /**
   * Props to pass to create suggested questions component
   */
  suggestedQuestionsProps?: SuggestedQuestionsProps
}

export const Toggle = (props: ToggleProps) => {
  const {
    label,
    suggestedQuestionsProps,
    field: { name, value },
    form: { setFieldValue, errors, touched },
    toggleOptions,
    buttonSize = 'full',
    toggleWrapperStyles,
  } = props
  const theme = useTheme()

  const genToggle = (option: ToggleInformation) => {
    const { id, text } = option
    const isSelected = id === value

    return (
      <Button
        sx={{ height: convertButtonSize(buttonSize) }}
        variant={isSelected ? 'contained' : 'plain'}
        onClick={() => setFieldValue(name, id, true)}
        key={id}
      >
        <Typography align="center">{text}</Typography>
      </Button>
    )
  }

  return (
    <InputWrapper
      name={name}
      label={label}
      suggestedQuestionsProps={suggestedQuestionsProps}
      touched={touched}
      errors={errors}
    >
      <Paper
        sx={{
          padding: 1,
          background: theme.palette.secondary.main,
          display: 'inline-flex',
          ...toggleWrapperStyles,
        }}
      >
        {toggleOptions.map((option) => genToggle(option))}
      </Paper>
    </InputWrapper>
  )
}
