import React from 'react'
import { Toggle } from '../components/inputs/Toggle'
import * as Yup from 'yup'
import { Field, Form, FormikProvider, useFormik } from 'formik'
import { getFormValue, resetForm, setFormAndSend } from '../store/form'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { useNavigate } from 'react-router-dom'
import { INDEX } from '../routes'
import { formFill } from '../util/sx'
import { Box, Typography } from '@mui/material'
import { TextField } from '../components/inputs/TextField'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'

interface SoftFailFormProps {
  formId: string
}
interface SoftFailFormValues {
  contactType?: string
  phoneNumber?: string
}

const formKey = 'softFail'

export const SoftFailForm = (props: SoftFailFormProps) => {
  const { formId } = props
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const toggleOptions = [t('email'), t('phone')]
  const formData = useAppSelector(getFormValue<SoftFailFormValues>(formKey))

  const initialValues = {
    contactType: formData?.contactType || t('email'),
    phoneNumber: formData?.phoneNumber,
  }

  const validationSchema = Yup.object({
    contactType: Yup.string().required(t('required')),
    phoneNumber: Yup.string().when('contactType', {
      is: t('phone'),
      then: Yup.string().required(t('required')),
      otherwise: Yup.string(),
    }),
  })

  const onSubmit = (values: typeof initialValues) => {
    dispatch(setFormAndSend({ formKey, values }))
    dispatch(resetForm())
    navigate(INDEX)
  }

  const formik = useFormik({ initialValues, validationSchema, onSubmit })

  return (
    <FormikProvider value={formik}>
      <Form id={formId} style={{ width: '100%' }}>
        <Box sx={formFill}>
          <Field
            name="contactType"
            component={Toggle}
            toggleOptions={toggleOptions.map((option) => ({
              id: option,
              text: option,
            }))}
            label={t('forms.softFail.contactTypeLabel')}
            buttonSize="half"
          ></Field>
          {formik.values.contactType === t('email') ? (
            <Box sx={{ pb: 5 }}>
              <Typography>{t('forms.softFail.confirmEmail')}</Typography>
            </Box>
          ) : (
            <Field
              component={TextField}
              name="phoneNumber"
              label={t('forms.softFail.bestPhoneNumberLabel')}
              placeholder={t('typeHere')}
            />
          )}
        </Box>
      </Form>
    </FormikProvider>
  )
}
