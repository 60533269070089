import stateCounties from '../assets/state-counties.json'

export type StateCounty = {
  state?: string
  state_abbr?: string
  county?: string
}

export function useGetCounties() {
  return stateCounties.sort((a, b) => {
    if (a.state === b.state) return a.county.localeCompare(b.county)
    return a.state.localeCompare(b.state)
  })
}
