import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { useAppDispatch } from '../../hooks/hooks'
import { restartSessionState } from '../../store/uneeq'
import { config } from '../../config'

export const Timeout = () => {
  const { idleDialogTimeout, idleTimeout } = config.timeouts
  const [showIdleDialog, setShowIdleDialog] = useState(false)
  const [dialogTimeoutLeft, setDialogTimeoutLeft] = useState(idleDialogTimeout)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const handleLeaveChat = useCallback(() => {
    dispatch(restartSessionState)
  }, [dispatch])

  useEffect(() => {
    if (!showIdleDialog) return
    const countdown: number = window.setInterval(() => {
      setDialogTimeoutLeft((prevDialogTime) => {
        if (prevDialogTime <= 0) {
          window.clearInterval(countdown)
          handleLeaveChat()
          return 0
        }
        return prevDialogTime - 1
      })
    }, 1000)

    return () => {
      window.clearInterval(countdown)
    }
  }, [showIdleDialog, handleLeaveChat])

  const onIdle = () => {
    if (showIdleDialog) return
    setShowIdleDialog(true)
    setDialogTimeoutLeft(idleDialogTimeout)
  }

  const handleContinue = () => {
    setShowIdleDialog(false)
  }

  useIdleTimer({
    onIdle,
    timeout: idleTimeout,
  })

  return (
    <Dialog
      open={showIdleDialog}
      sx={{ zIndex: 999999 }}
      PaperProps={{ sx: { p: 4 } }}
      onClose={handleContinue}
    >
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Typography align="center" variant="h5">
            {t('stillThere')}
          </Typography>
          <Typography align="center">{t('redirecting')}</Typography>
          <Typography align="center" variant="h3">
            {dialogTimeoutLeft}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleLeaveChat}>
          {t('leaveChat')}
        </Button>
        <Button variant="outlined" onClick={handleContinue}>
          {t('backToChat')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
