/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Field, getIn } from 'formik'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { Box, Button, InputAdornment } from '@mui/material'
import { TextField } from '../../components/inputs/TextField'
import { Toggle } from '../../components/inputs/Toggle'
import { SubFormProps } from '../../types'
import { Autocomplete } from '../../components/inputs/Autocomplete'

export const IncomeSourcesSubForm = (props: SubFormProps) => {
  const {
    fieldPath,
    onDelete,
    form: { values },
    deleteButtonText,
  } = props
  const { t } = useTranslation()
  const toggleOptions = [t('monthly'), t('yearly')]
  const dropdownOptions = [
    t('salary'),
    t('forms.incomeSources.allowanceSpousalIncome'),
    t('forms.incomeSources.interestDividends'),
    t('forms.incomeSources.pension'),
    t('other'),
  ]
  const incomeTypeFieldPath = `${fieldPath}.incomeType`
  const isTypeSalary = getIn(values, incomeTypeFieldPath) === t('salary')

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box>
        <Field
          component={Autocomplete}
          name={incomeTypeFieldPath}
          label={t('forms.incomeSources.incomeTypeLabel')}
          placeholder={t('chooseOption')}
          options={dropdownOptions}
        />
      </Box>

      <Box sx={{ display: 'flex', gap: 2 }}>
        <Field
          name={`${fieldPath}.incomeBasePay`}
          component={TextField}
          label={
            isTypeSalary
              ? t('forms.incomeSources.basePayLabel')
              : t('forms.incomeSources.incomeLabel')
          }
          placeholder={t('numberPlaceholder')}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          type="text"
        />
        <Field
          name={`${fieldPath}.incomeBaseFrequency`}
          component={Toggle}
          toggleOptions={toggleOptions.map((option) => ({
            id: option,
            text: option,
          }))}
          label={t('forms.incomeSources.frequencyLabel')}
          buttonSize="half"
        />
      </Box>

      {isTypeSalary && (
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Field
            name={`${fieldPath}.incomeBonusPay`}
            component={TextField}
            label={t('forms.incomeSources.incomeBonusPayLabel')}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            placeholder={t('numberPlaceholder')}
            type="text"
          />
          <Field
            name={`${fieldPath}.incomeBonusFrequency`}
            component={Toggle}
            toggleOptions={toggleOptions.map((option) => ({
              id: option,
              text: option,
            }))}
            label={t('forms.incomeSources.frequencyLabel')}
            buttonSize="half"
          />
        </Box>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onDelete} variant="contained" color="error">
          {deleteButtonText}
        </Button>
      </Box>
    </Box>
  )
}
