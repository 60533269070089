import React, { useEffect } from 'react'
import { Box, Button, Typography, Link } from '@mui/material'
import { flexFill } from '../util/sx'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { useNavigate } from 'react-router-dom'
import { INDEX } from '../routes'
import { convertButtonSize } from '../util/button'
import { setProgress } from '../store/progressBar'
import { useAppDispatch } from '../hooks/hooks'
import { useUneeqAvatar } from '../hooks/uneeq'

export const LocationFailPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  useUneeqAvatar(t('pages.locationFail.avatarIntro'))

  useEffect(() => {
    dispatch(setProgress(100))
  })

  return (
    <Box
      sx={{
        ...flexFill,
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ maxWidth: 400, mb: 7 }}>
        <Typography sx={{ display: 'flex' }}>
          {t('pages.locationFail.locationUnlicensed')}
        </Typography>
      </Box>
      <Box sx={{ maxWidth: 400, mb: 7 }}>
        <Typography>
          <Link
            href="https://instamortgage.com/home/about-us/licensing/"
            target="_blank"
          >
            {t('pages.locationFail.linkText')}
          </Link>
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          variant="outlined"
          onClick={() => navigate(-1)}
          sx={{ height: convertButtonSize('full') }}
        >
          {t('back')}
        </Button>
        <Button
          variant="contained"
          onClick={() => navigate(INDEX)}
          sx={{ height: convertButtonSize('full') }}
        >
          {t('startOver')}
        </Button>
      </Box>
    </Box>
  )
}
