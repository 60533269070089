/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react'
import { Box, SxProps, Theme } from '@mui/material'
import { SuggestedQuestionsProps } from '../popups/SuggestedQuestions'
import { InputLabel } from './InputLabel'
import { FormikErrors, FormikTouched } from 'formik'
import { ErrorHelper } from './ErrorHelper'

interface InputWrapperProps {
  /**
   * Component that will trigger the popout
   */
  children?: ReactElement | ReactElement[]

  /**
   * Label title for the input
   */
  label: React.ReactNode

  /**
   * Props to pass to create suggested questions component
   */
  suggestedQuestionsProps?: SuggestedQuestionsProps

  /**
   * Styles to apply to the container around
   */
  inputWrapperStyles?: SxProps<Theme>

  /**
   * Styles to apply to the container around
   */
  labelStyles?: SxProps<Theme>

  /**
   * Styles to apply to the container around
   */
  errorHelperStyles?: SxProps<Theme>

  /**
   * Formik errors object from the form
   */
  errors: FormikErrors<any>

  /**
   * Formik touched object from the form
   */
  touched: FormikTouched<any>

  /**
   * Formik form name for the input
   */
  name: string
}

export const InputWrapper = (props: InputWrapperProps) => {
  const {
    label,
    suggestedQuestionsProps,
    name,
    children,
    touched,
    errors,
    inputWrapperStyles,
    labelStyles,
    errorHelperStyles,
  } = props

  return (
    <Box>
      <Box>
        <InputLabel
          htmlFor={name}
          sx={{ display: 'flex', ...labelStyles }}
          label={label}
          suggestedQuestionsProps={suggestedQuestionsProps}
        />
      </Box>
      <Box
        sx={{
          ...inputWrapperStyles,
        }}
      >
        {children}
      </Box>
      <Box>
        <ErrorHelper
          name={name}
          touched={touched}
          errors={errors}
          errorHelperStyles={errorHelperStyles}
        />
      </Box>
    </Box>
  )
}
