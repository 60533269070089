import React, { useEffect } from 'react'
import { Box, Button } from '@mui/material'
import { HomeInformationForm } from '../forms/HomeInformationForm'
import { setProgressValueByRoute } from '../store/progressBar'
import { flexFill } from '../util/sx'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { useNavigate } from 'react-router-dom'
import { convertButtonSize } from '../util/button'
import { useUneeqAvatar } from '../hooks/uneeq'
import { INDEX } from '../routes'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { getFormValue } from '../store/form'
import {
  PersonalDetailsFormValues,
  personalDetailsKey,
} from '../forms/PersonalDetailsForm'

export const HomeInformationPage = () => {
  const dispatch = useAppDispatch()
  const formId = 'home-information-form'
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { firstName } = useAppSelector(
    getFormValue<PersonalDetailsFormValues>(personalDetailsKey)
  )
  const { speakText } = useUneeqAvatar()

  useEffect(() => {
    if (!firstName) {
      return
    }

    speakText(t('pages.homeInformation.avatarIntro', { firstName }))
  }, [firstName, speakText, t])

  useEffect(() => {
    dispatch(setProgressValueByRoute())
  })

  const pageBack = () => {
    navigate(INDEX)
  }

  return (
    <Box
      sx={{
        ...flexFill,
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 4,
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <HomeInformationForm formId={formId} />
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          variant="outlined"
          onClick={pageBack}
          sx={{ height: convertButtonSize('full') }}
        >
          {t('back')}
        </Button>
        <Button
          variant="contained"
          form={formId}
          type="submit"
          sx={{ height: convertButtonSize('full') }}
        >
          {t('next')}
        </Button>
      </Box>
    </Box>
  )
}
