import { setForm, resetForm } from '.'
import { Middleware } from '@reduxjs/toolkit'
export const FORM_SESSION_KEY = 'instamortgageFormData'

export const formSessionStorageMiddleware: Middleware =
  () => (next) => (action) => {
    if (setForm.match(action)) {
      const {
        payload: { formKey, values },
      } = action

      const storedFormData = JSON.parse(
        sessionStorage.getItem(FORM_SESSION_KEY) || '{}'
      )

      sessionStorage.setItem(
        FORM_SESSION_KEY,
        JSON.stringify({ ...storedFormData, [formKey]: values })
      )
    }

    if (resetForm.match(action)) {
      sessionStorage.setItem(FORM_SESSION_KEY, '{}')
    }

    return next(action)
  }
