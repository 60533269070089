import { colors } from "../theme"
import { convertButtonSize } from "./button"

export const flexFill = { display: 'flex', height: '100%', width: '100%' }
export const formFill = { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }
export const unitToggle = { width: '40px', minWidth: '0px', height: convertButtonSize("half")}
export const horizontalFlex = { display: 'flex', flexDirection: 'row', gap: 9, px: 9 }
export const calculatorInputStyles = { pr: 1, border: 0 }
export const calculatorLabelStyles = { fontSize: '18px', fontWeight: 400 }
export const whiteBox = { backgroundColor: colors.white, borderRadius: 1 }

export const calculatorPageLayout = {
    ...flexFill,
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 4,
    maxWidth: '570px',
    py: 10
}
