import React, { useEffect } from 'react'
import { Box, Button } from '@mui/material'
import { IncomeSourcesForm } from '../forms/IncomeSourcesForm'
import { setProgressValueByRoute } from '../store/progressBar'
import { flexFill } from '../util/sx'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { useNavigate } from 'react-router-dom'
import { convertButtonSize } from '../util/button'
import { useUneeqAvatar } from '../hooks/uneeq'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { getFormValue } from '../store/form'
import { CREDIT_SCORE, HOME_DETAILS } from '../routes'
import { CreditScoreFormValues } from '../forms/CreditScoreForm'

export const IncomeSourcesPage = () => {
  const dispatch = useAppDispatch()
  const formId = 'income-sources-form'
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { ownHome } = useAppSelector(
    getFormValue<CreditScoreFormValues>('creditScore')
  )
  useUneeqAvatar(t('pages.incomeSources.avatarIntro'))

  useEffect(() => {
    dispatch(setProgressValueByRoute())
  })

  const pageBack = () => {
    navigate(ownHome === t('yes') ? HOME_DETAILS : CREDIT_SCORE)
  }

  return (
    <Box
      sx={{
        ...flexFill,
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 4,
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <IncomeSourcesForm formId={formId} />
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          variant="outlined"
          onClick={pageBack}
          sx={{ height: convertButtonSize('full') }}
        >
          {t('back')}
        </Button>
        <Button
          variant="contained"
          form={formId}
          type="submit"
          sx={{ height: convertButtonSize('full') }}
        >
          {t('next')}
        </Button>
      </Box>
    </Box>
  )
}
