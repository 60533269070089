import React from 'react'
import { Box, Typography } from '@mui/material'
import { StartSessionButton } from '../components/buttons/StartSessionButton'
import { flexFill } from '../util/sx'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import rachelImage from '../assets/rachel.svg'
import logo from '../assets/instamortage-logo.png'

export const LandingPage = () => {
  const { t } = useTranslation()

  return (
    <Box sx={{ ...flexFill }}>
      <Box
        sx={{
          ...flexFill,
          flex: 1,
          padding: 8,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ position: 'absolute', top: 32, left: 32 }}>
          <img src={logo} alt="logo" />
        </Box>
        <Box
          sx={{
            width: '500px',
            height: '500px',
            background: '#F3F7FA',
            border: '8px solid #45A1E3',
            boxSizing: 'border-box',
            borderRadius: '350px',
            overflow: 'hidden',
          }}
        >
          <img src={rachelImage} alt="rachel" />
        </Box>
      </Box>
      <Box sx={{ flex: 1, padding: 4, maxWidth: '50%' }}>
        <Box
          sx={{
            ...flexFill,
            maxWidth: '540px',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 6,
          }}
        >
          <Box>
            <Typography variant="h5">{t('pages.landing.header')}</Typography>
          </Box>
          <Box>
            <Typography>{t('pages.landing.text')}</Typography>
          </Box>
          <Box>
            <StartSessionButton variant="contained" />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
