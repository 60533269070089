import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import { flexFill } from '../util/sx'

export const LoadingPage = () => (
  <Box
    sx={{
      ...flexFill,
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress />
  </Box>
)
