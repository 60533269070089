export const allowedStates = [
  'AL',
  'AZ',
  'CA',
  'CO',
  'CT',
  'FL',
  'GA',
  'ID',
  'IL',
  'IN',
  'MD',
  'MI',
  'MN',
  'NV',
  'NJ',
  'NY',
  'NC',
  'OH',
  'OK',
  'OR',
  'PA',
  'SC',
  'TN',
  'TX',
  'VA',
  'WA',
]
