import React from 'react'
import { Box, Button, Paper, SxProps, Theme, Typography } from '@mui/material'
import { useUneeqAvatar } from '../../hooks/uneeq'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { ButtonSize } from '../../types'
import { convertButtonSize } from '../../util/button'

export interface Question {
  title: string
  utterance: string
}

interface SuggestedQuestionProps {
  /**
   * Suggested question to render
   */
  question: Question
  /**
   * Size of 'ask' buttons
   */
  askButtonSize?: ButtonSize
  /**
   *  Stylings for Suggested Questions Paper
   */
  suggestedQuestionStyles?: SxProps<Theme>
}

export const SuggestedQuestion = (props: SuggestedQuestionProps) => {
  const { question, askButtonSize = 'half', suggestedQuestionStyles } = props
  const { sendText } = useUneeqAvatar()
  const { t } = useTranslation()

  return (
    <Paper
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        p: 2,
        ...suggestedQuestionStyles,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
        <Typography>{question.title}</Typography>
      </Box>

      <Button
        variant="contained"
        size="small"
        onClick={() => {
          sendText(question.utterance)
        }}
        sx={{ height: convertButtonSize(askButtonSize) }}
      >
        {t('ask')}
      </Button>
    </Paper>
  )
}
