import { t } from 'i18next'

const createSuggestedQuestion = (title: string, utterance: string) => ({
  title,
  utterance,
})

export const useCreateSuggestedQuestions = (
  questionNames: string[] | string
) => {
  const _questionNames =
    typeof questionNames == 'string' ? [questionNames] : questionNames

  return _questionNames.map((questionName) => {
    return createSuggestedQuestion(
      t(`suggestedQuestions.${questionName}.title`),
      t(`suggestedQuestions.${questionName}.utterance`)
    )
  })
}
