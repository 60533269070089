import axios from 'axios'
import jwtDecode from 'jwt-decode'
import {
  selectSentQuestion,
  selectAvatarSpeech,
  selectSessionIdJwt,
  selectAvatarSpeaking,
} from '../store/uneeq'
import { useEffect, useState } from 'react'
import { config } from '../config'
import { useAppSelector } from './hooks'

const uneeqSpeak = async (
  sessionIdJwt: string,
  answer: string,
  instructions?: object
) => {
  const answerAvatar = JSON.stringify(instructions ?? {})
  const { sessionId } = jwtDecode<{ sessionId: string }>(sessionIdJwt)

  const body = {
    answer,
    answerAvatar,
    sessionIdJwt,
  }

  await axios.post(`/v1/avatar/${sessionId}/speak`, body, {
    baseURL: config.uneeq.baseUrl(),
  })
}

export function useUneeqAvatar(initialMessage?: string) {
  const { uneeqAsk: sendText, uneeqStopSpeaking } = window

  const sentQuestion = useAppSelector(selectSentQuestion)
  const avatarSpeech = useAppSelector(selectAvatarSpeech)
  const sessionIdJwt = useAppSelector(selectSessionIdJwt)
  const avatarSpeaking = useAppSelector(selectAvatarSpeaking)

  const [speakText, setSpeakText] = useState<string>()
  const [sentInitialMessage, setSentInitialMessage] = useState(false)

  /**
   * One off when page is first rendered
   */
  useEffect(() => {
    if (avatarSpeaking && config.uneeq.shouldInterruptSpeechBetweenPages()) {
      // only interrupt if speaking and we have configured it to do so
      uneeqStopSpeaking()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Speak initial intro message when ready
   */
  useEffect(() => {
    if (avatarSpeaking || sentInitialMessage || !initialMessage) {
      return
    }

    setSpeakText(initialMessage)
    setSentInitialMessage(true)
  }, [speakText, avatarSpeaking, sentInitialMessage, initialMessage])

  /**
   * Only speak when the text to be spoken has been set, and we have session JWT available
   */
  useEffect(() => {
    if (!sessionIdJwt || !speakText) {
      return
    }

    uneeqSpeak(sessionIdJwt, speakText);
    setSpeakText(undefined);
  }, [sessionIdJwt, speakText])

  return {
    sendText,
    sessionIdJwt,
    speakText: setSpeakText,
    sentQuestion,
    avatarSpeech,
    avatarSpeaking,
  }
}
