/* eslint-disable @typescript-eslint/no-explicit-any */
import { formSlice } from './slice'
import { Dispatch } from '@reduxjs/toolkit'
import { RootState } from '../store'
import axios from 'axios'
import { getFlattenedForm } from './selectors'
import { config } from '../../config'

export const { setForm, resetForm } = formSlice.actions

// Save the form and also send the results to Zapie
export const setFormAndSend =
  (action: any) => async (dispatch: Dispatch, getState: () => RootState) => {
    // Dispatch and update the state
    dispatch(setForm(action))

    const data = getState()
    const flatData = getFlattenedForm(data)

    try {
      await axios.post(config.uneeq.zapierUrl, flatData, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      })
    } catch (e) {
      console.error(e)
    }
  }
