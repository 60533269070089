import { FormikErrors, useFormikContext } from "formik";
import { useEffect } from "react";

interface FormErrorListenerProps {
  /**
   * Error Handler triggered everytime a field has an error
   */
  onError?: (errors: FormikErrors<unknown>) => void;

  /**
   * Error Handler triggered when a a field has an error on submit
   */
  onSubmitError?: (errors: FormikErrors<unknown>) => void;
}

export const FormErrorListener = ({ onError, onSubmitError }: FormErrorListenerProps) => {
  const { errors, isSubmitting, isValidating, isValid } = useFormikContext();
  const hasErrors = (errors: FormikErrors<unknown>) => Object.keys(errors).length > 0

  // onError Effect
  useEffect(() => {
    if (!onError || !hasErrors(errors)) {
      return;
    }

    onError(errors);
  }, [onError, errors]);

  // onSubmitError Effect
  useEffect(() => {
    const _isSubmitting = isSubmitting && !isValidating;
    if (!onSubmitError || !hasErrors(errors) || !_isSubmitting) {
      return;
    }

    onSubmitError(errors);
  }, [onSubmitError, errors, isSubmitting, isValidating, isValid]);

  return null;
}
