import React from 'react'

import { Box, Paper, Typography, SxProps, Theme, useTheme } from '@mui/material'
import { SuggestedQuestionsProps } from '../popups/SuggestedQuestions'
import { FieldProps } from 'formik'
import { InputWrapper } from './InputWrapper'

interface CardInformation {
  /**
   * Id of the card to save to the form
   */
  id: string

  /**
   * Title to display on the card
   */
  title: string

  /**
   * Url of image to display
   */
  imgUrl?: string
}

interface CardSelectProps extends FieldProps {
  /**
   * List of cards to display
   */
  cardList: CardInformation[]

  /**
   * Styles to apply to the cards
   */
  cardStyles?: SxProps<Theme>

  /**
   * Styles to apply to the container around
   */
  cardContainerStyles?: SxProps<Theme>

  /**
   * Label title for the input
   */
  label: React.ReactNode

  /**
   * Props to pass to create suggested questions component
   */
  suggestedQuestionsProps?: SuggestedQuestionsProps
}

export const CardSelect = (props: CardSelectProps) => {
  const {
    label,
    suggestedQuestionsProps,
    cardList,
    cardStyles,
    cardContainerStyles,
    field: { name, value },
    form: { setFieldValue, errors, touched },
  } = props
  const theme = useTheme()

  const genCard = (card: CardInformation) => {
    const { id, title, imgUrl } = card
    const isSelected = id === value
    return (
      <Paper
        onClick={() => setFieldValue(name, id, true)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ':hover': {
            cursor: 'pointer',
          },
          boxSizing: 'border-box',
          background: theme.palette.secondary.main,
          ...(isSelected && { border: 4, borderColor: 'primary.main' }),
          ...cardStyles,
        }}
        key={id}
      >
        {imgUrl && (
          <Box>
            <img src={imgUrl} alt={`${title}`} />
          </Box>
        )}
        <Typography align="center">{title}</Typography>
      </Paper>
    )
  }

  return (
    <InputWrapper
      name={name}
      label={label}
      inputWrapperStyles={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: 4,
        ...cardContainerStyles,
      }}
      suggestedQuestionsProps={suggestedQuestionsProps}
      touched={touched}
      errors={errors}
    >
      {cardList.map((card) => genCard(card))}
    </InputWrapper>
  )
}
