import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { routes } from './routes'
import { Box, CssBaseline } from '@mui/material'
import logo from './assets/instamortage-logo.png'
import { LinearProgress } from './components/progressBars/LinearProgress'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { Avatar } from './components/uneeq/Avatar'
import { UneeqSessionProvider } from './components/uneeq/UneeqSessionProvider'
import { LandingPage } from './pages/LandingPage'
import { LoadingPage } from './pages/LoadingPage'
import { flexFill } from './util/sx'
import { RouteMiddleware } from './components/routing/RouteMiddleware'
import { Timeout } from './components/timeout/Timeout'

function App() {
  return (
    <Router>
      <Provider store={store}>
        <RouteMiddleware>
          <Box sx={{ height: '100%' }}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <UneeqSessionProvider
                landingPage={<LandingPage />}
                loadingPage={<LoadingPage />}
              >
                <Timeout />
                <LinearProgress variant="determinate" color="primary" />
                <Box sx={{ ...flexFill }}>
                  <Box sx={{ flex: 1, padding: 8 }}>
                    <img src={logo} alt="logo" />
                    <Avatar />
                  </Box>
                  <Box sx={{ flex: 1, padding: 4, maxWidth: '50%' }}>
                    <Routes>
                      {routes.map((route) => (
                        <Route key={route.path} {...route} />
                      ))}
                    </Routes>
                  </Box>
                </Box>
              </UneeqSessionProvider>
            </ThemeProvider>
          </Box>
        </RouteMiddleware>
      </Provider>
    </Router>
  )
}

export default App
