import React from 'react'
import * as Yup from 'yup'
import { Field, Formik, Form } from 'formik'
import { getFormValue, setForm } from '../store/form'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { useNavigate } from 'react-router-dom'
import { HOME_DETAILS, INCOME_SOURCES } from '../routes'
import { formFill } from '../util/sx'
import { Box } from '@mui/material'
import { TextField } from '../components/inputs/TextField'
import { Toggle } from '../components/inputs/Toggle'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { useUneeqAvatar } from '../hooks/uneeq'
import { FormErrorListener } from '../components/form/FormErrorListener'

interface CreditScoreFormProps {
  formId: string
}

export interface CreditScoreFormValues {
  creditScore?: number
  ownHome?: string
}
const formKey = 'creditScore'

export const CreditScoreForm = (props: CreditScoreFormProps) => {
  const { formId } = props
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { speakText } = useUneeqAvatar()
  const toggleOptions = [t('yes'), t('no')]
  const formData = useAppSelector(getFormValue<CreditScoreFormValues>(formKey))

  const initialValues: CreditScoreFormValues = {
    creditScore: formData?.creditScore,
    ownHome: formData?.ownHome || t('yes'),
  }

  const validationSchema = Yup.object({
    creditScore: Yup.number()
      .typeError(t('mustBeNumber'))
      .min(0, t('minimumNumber'))
      .required(t('required')),
    ownHome: Yup.string().required(t('required')),
  })

  const onSubmit = (values: typeof initialValues) => {
    dispatch(setForm({ formKey, values }))
    navigate(values.ownHome === t('yes') ? HOME_DETAILS : INCOME_SOURCES)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <>
        <FormErrorListener onSubmitError={() => speakText(t('formError'))} />
        <Form id={formId} style={{ width: '100%' }}>
          <Box sx={formFill}>
            <Field
              component={TextField}
              name="creditScore"
              label={t('forms.creditScore.creditScoreLabel')}
              placeholder={t('typeHere')}
              type="text"
            />
            <Field
              name="ownHome"
              component={Toggle}
              toggleOptions={toggleOptions.map((option) => ({
                id: option,
                text: option,
              }))}
              label={t('forms.creditScore.ownHomeLabel')}
              buttonSize="half"
            />
          </Box>
        </Form>
      </>
    </Formik>
  )
}
