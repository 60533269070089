import { createSlice } from '@reduxjs/toolkit'
import { FORM_SESSION_KEY } from './middleware'

export interface FormSlice {
  data: { [key: string]: { [key: string]: number | string | object } }
}

const initialState: FormSlice = {
  data: JSON.parse(sessionStorage.getItem(FORM_SESSION_KEY) || '{}'),
}

export const formSlice = createSlice({
  name: 'formSlice',
  initialState,
  reducers: {
    setForm: (state, action) => {
      // Save each value for the form
      state.data[action.payload.formKey] = action.payload.values
    },
    resetForm: (state) => {
      state.data = {}
    },
  },
})
