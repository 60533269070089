/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import * as Yup from 'yup'
import { Formik, Form, FieldArray } from 'formik'
import { getFormValue, setForm } from '../store/form'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { useNavigate } from 'react-router-dom'
import { ASSETS } from '../routes'
import { AccordionForm } from '../components/inputs/AccordionForm'
import { IncomeSourcesSubForm } from './subForms/IncomeSourcesSubForm'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { FormErrorListener } from '../components/form/FormErrorListener'
import { useUneeqAvatar } from '../hooks/uneeq'

interface IncomeSourcesFormProps {
  formId: string
}

export interface IncomeSource {
  incomeType?: string
  incomeBasePay?: number
  incomeBaseFrequency?: string
  incomeBonusPay?: number
  incomeBonusFrequency?: string
}

export interface IncomeSourcesFormValues {
  incomeSources: IncomeSource[]
}

export const formKey = 'incomeSources'

export const IncomeSourcesForm = (props: IncomeSourcesFormProps) => {
  const { formId } = props
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { speakText } = useUneeqAvatar()
  const formData = useAppSelector(
    getFormValue<IncomeSourcesFormValues>(formKey)
  )

  const defaultIncomeSource: IncomeSource = {
    incomeType: undefined,
    incomeBasePay: undefined,
    incomeBaseFrequency: t('monthly'),
    incomeBonusPay: undefined,
    incomeBonusFrequency: t('monthly'),
  }

  const initialValues: IncomeSourcesFormValues = {
    incomeSources: formData?.incomeSources || [defaultIncomeSource],
  }

  const validationSchema = Yup.object({
    incomeSources: Yup.array().of(
      Yup.object().shape({
        incomeType: Yup.string().required(t('required')).nullable(),
        incomeBasePay: Yup.number()
          .typeError(t('mustBeNumber'))
          .required(t('required')),
        incomeBaseFrequency: Yup.string().required(t('required')),
        incomeBonusPay: Yup.number().typeError(t('mustBeNumber')),
        incomeBonusFrequency: Yup.string(),
      })
    ),
  })

  const transformValues = (values: typeof initialValues) => {
    if (!values.incomeSources) return {}
    return {
      incomeSources: values.incomeSources.map((incomeSource) => {
        const {
          incomeType,
          incomeBonusFrequency,
          incomeBonusPay,
          ...restIncomeSource
        } = incomeSource
        const isSalary = incomeType === t('salary')

        return {
          incomeType,
          incomeBonusFrequency: isSalary ? incomeBonusFrequency : undefined,
          incomeBonusPay: isSalary ? incomeBonusPay : undefined,
          ...restIncomeSource,
        }
      }),
    }
  }

  const onSubmit = (values: typeof initialValues) => {
    dispatch(
      setForm({
        formKey,
        values: transformValues(values),
      })
    )
    navigate(ASSETS)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <>
        <FormErrorListener onSubmitError={() => speakText(t('formError'))} />
        <Form id={formId} style={{ width: '100%', maxWidth: '560px' }}>
          <FieldArray
            name="incomeSources"
            render={(props) => {
              return (
                <AccordionForm
                  label={t('forms.incomeSources.incomeSourcesLabel')}
                  fieldProps={props}
                  subForm={IncomeSourcesSubForm}
                  subFormProps={{
                    deleteButtonText: t('forms.incomeSources.removeIncome'),
                  }}
                  header={t('forms.incomeSources.incomeSource')}
                  fieldName="incomeSources"
                  addButtonText={t('forms.incomeSources.addIncome')}
                  defaultValue={defaultIncomeSource}
                  accordionContainerStyles={{
                    maxHeight: '500px',
                    borderRadius: '4px 4px 0 0',
                  }}
                  buttonContainerStyles={{
                    borderRadius: '0 0 4px 4px',
                    position: 'relative',
                    top: '1px',
                  }}
                />
              )
            }}
          />
        </Form>
      </>
    </Formik>
  )
}
