/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import {
  Autocomplete as MuiAutocomplete,
  FilterOptionsState,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputProps,
} from '@mui/material'
import { SuggestedQuestionsProps } from '../popups/SuggestedQuestions'
import { FieldProps } from 'formik'
import { InputWrapper } from './InputWrapper'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'

interface AutocompleteProps extends FieldProps, InputProps {
  /**
   * Label title for the input
   */
  label: React.ReactNode

  /**
   * Props to pass to create suggested questions component
   */
  suggestedQuestionsProps?: SuggestedQuestionsProps

  /**
   * Options to suggest in Autocomplete
   */
  options: any[]

  /**
   * Function to choose how options are grouped
   */
  groupBy?: (option: any) => string

  /**
   * Function to filter options
   */
  filterOptions?:
  | ((options: any[], state: FilterOptionsState<any>) => any[])
  | undefined

  /**
   * Function to choose the label that is displayed
   */
  getOptionLabel?: ((option: any) => string) | undefined
}

export const Autocomplete = (props: AutocompleteProps) => {
  const [open, setOpen] = useState(false)

  const {
    label,
    suggestedQuestionsProps,
    id,
    placeholder,
    options,
    groupBy,
    filterOptions,
    getOptionLabel,
    field: { name, value },
    form: { errors, touched, handleBlur, setFieldValue },
  } = props

  const handleChange = (event: any, value: any) => {
    setFieldValue(name, value)
  }

  return (
    <FormControl fullWidth variant="standard">
      <InputWrapper
        name={name}
        label={label}
        suggestedQuestionsProps={suggestedQuestionsProps}
        touched={touched}
        errors={errors}
      >
        <MuiAutocomplete
          filterOptions={filterOptions}
          id={name}
          options={options}
          disablePortal
          onBlur={handleBlur}
          onChange={handleChange}
          value={value || ''}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          getOptionLabel={getOptionLabel}
          groupBy={groupBy}
          fullWidth
          renderInput={(params) => (
            <Input
              id={name || id}
              aria-describedby={`${name}-error`}
              placeholder={placeholder || name || id}
              value={value}
              error={!!(touched[name] && errors[name])}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle dropdown"
                    edge="end"
                    onClick={() => setOpen(!open)}
                    sx={{ ':hover': { backgroundColor: 'inherit' } }}
                  >
                    {open ? <ArrowDropUp /> : <ArrowDropDown />}
                  </IconButton>
                </InputAdornment>
              }
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
            />
          )}
        />
      </InputWrapper>
    </FormControl>
  )
}
