declare global {
  interface Window {
    buildIframeSrc: BuildIframeSrc
    isShareIdValid: IsShareIdValid
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    uneeqInteractionsOptions: any
    uneeqAddFrame: UneeqAddFrame
    uneeqAsk: UneeqAsk
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    uneeqFrame: any
    uneeqOpenStartSessionPopup: UneeqOpenStartSessionPopup
    uneeqStartSession: UneeqStartSession
    uneeqSetShowUserInputInterface: UneeqSetShowUserInputInterface
    uneeqStopSpeaking: UneeqStopSpeaking
    uneeqEndSession: UneeqEndSession
  }
  // Global properties made available by SDK (can't call them via window though)
  const uneeqSessionLive: boolean | undefined
  const uneeqState: UneeqSessionStateValue | undefined
}

export type BuildIframeSrc = () => string
export type IsShareIdValid = (id: string) => boolean
export type UneeqAddFrame = () => void
export type UneeqAsk = (a: string) => void
export type UneeqOpenStartSessionPopup = (a: {
  content: string
  startButtonText: string
}) => void
export type UneeqStartSession = () => void
export type UneeqSetShowUserInputInterface = (show: boolean) => void
export type UneeqStopSpeaking = () => void
export type UneeqEndSession = () => void

export enum UneeqMessageType {
  QUESTION_TEXT = 'AvatarQuestionText',
  ANSWER_CONTENT = 'AvatarAnswerContent',
  ANSWER = 'AvatarAnswer',
  READY = 'Ready',
  STARTED_SPEAKING = 'StartedSpeaking',
  FINISHED_SPEAKING = 'FinishedSpeaking',
}

export enum UneeqSessionStateValue {
  INITIAL = 'Initial',
  WAITING_TO_START = 'WaitingToStart',
  LIVE = 'Live',
}

export enum SessionState {
  INITIAL,
  SDK_READY,
  LOADING_AVATAR,
  AVATAR_LIVE,
}

export interface UneeqSessionStateEvent extends Event {
  detail: UneeqSessionState
}

export interface UneeqEvent extends Event {
  detail: UneeqEventDetail
}

export interface UneeqEventDetail {
  uneeqMessageType: UneeqMessageType
}

export interface QuestionTextEventDetail extends UneeqEventDetail {
  question: string
}

export interface AnswerEventDetail extends UneeqEventDetail {
  answer: string
  answerAvatar: string
  answerSpeech: string
}

export interface UneeqSessionState {
  state: UneeqSessionStateValue
  live: boolean
}
