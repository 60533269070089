import React, { useEffect } from 'react'
import { Box, Button } from '@mui/material'
import { FinancingForm } from '../forms/FinancingForm'
import { setProgressValueByRoute } from '../store/progressBar'
import { flexFill } from '../util/sx'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { useNavigate } from 'react-router-dom'
import { convertButtonSize } from '../util/button'
import { useUneeqAvatar } from '../hooks/uneeq'
import { HOME_INFORMATION } from '../routes'
import { useAppDispatch } from '../hooks/hooks'

export const FinancingPage = () => {
  const dispatch = useAppDispatch()
  const formId = 'financing-form'
  const { t } = useTranslation()
  const navigate = useNavigate()
  useUneeqAvatar(t('pages.financing.avatarIntro'))

  useEffect(() => {
    dispatch(setProgressValueByRoute())
  })

  const pageBack = () => {
    navigate(HOME_INFORMATION)
  }

  return (
    <Box
      sx={{
        ...flexFill,
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 4,
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <FinancingForm formId={formId} />
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          variant="outlined"
          onClick={pageBack}
          sx={{ height: convertButtonSize('full') }}
        >
          {t('back')}
        </Button>
        <Button
          variant="contained"
          form={formId}
          type="submit"
          sx={{ height: convertButtonSize('full') }}
        >
          {t('next')}
        </Button>
      </Box>
    </Box>
  )
}
