import React from 'react'
import { TextField } from '../components/inputs/TextField'
import * as Yup from 'yup'
import { Field, Formik, Form } from 'formik'
import { getFormValue, setFormAndSend } from '../store/form'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { useNavigate } from 'react-router-dom'
import { LOADING_RESULTS } from '../routes'
import { formFill } from '../util/sx'
import { Box } from '@mui/material'
import { isPossiblePhoneNumber } from 'libphonenumber-js'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { FormErrorListener } from '../components/form/FormErrorListener'
import { useUneeqAvatar } from '../hooks/uneeq'

interface PhoneNumberFormProps {
  formId: string
}

interface PhoneNumberFormValues {
  phoneNumber?: string
}

const formKey = 'phoneNumber'

export const PhoneNumberForm = (props: PhoneNumberFormProps) => {
  const { formId } = props
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { speakText } = useUneeqAvatar()
  const formData = useAppSelector(getFormValue<PhoneNumberFormValues>(formKey))

  const initialValues: PhoneNumberFormValues = {
    phoneNumber: formData?.phoneNumber,
  }

  const validationSchema = Yup.object({
    phoneNumber: Yup.string()
      .required(t('required'))
      .test('is-valid-phone-number', t('validPhoneNumber'), (value) => {
        if (!value) return false
        return isPossiblePhoneNumber(value, 'US')
      }),
  })

  const onSubmit = (values: typeof initialValues) => {
    dispatch(setFormAndSend({ formKey, values }))
    navigate(LOADING_RESULTS)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <>
        <FormErrorListener onSubmitError={() => speakText(t('formError'))} />
        <Form id={formId}>
          <Box sx={formFill}>
            <Field
              component={TextField}
              name="phoneNumber"
              label={t('forms.phoneNumber.phoneNumberLabel')}
              placeholder={t('typeHere')}
            />
          </Box>
        </Form>
      </>
    </Formik>
  )
}
