/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import * as Yup from 'yup'
import { Field, Form, FormikProvider, useFormik } from 'formik'
import { getFormValue, setForm } from '../store/form'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { useNavigate } from 'react-router-dom'
import { CREDIT_SCORE, MORTGAGE_CALCULATOR } from '../routes'
import { formFill, unitToggle } from '../util/sx'
import { Box, Button, InputAdornment, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { TextField } from '../components/inputs/TextField'
import { Toggle } from '../components/inputs/Toggle'
import { useCreateSuggestedQuestions } from '../hooks/suggestedQuestions'
import { HomeInformationFormValues } from './HomeInformationForm'
import { OpenCalculatorButton } from '../components/buttons/OpenCalculatorButton'
import { FormErrorListener } from '../components/form/FormErrorListener'
import { useUneeqAvatar } from '../hooks/uneeq'

interface FinancingFormProps {
  formId: string
}

enum UnitOptions {
  Dollars = '$',
  Percentage = '%',
}

export interface FinancingFormValues {
  purchasePrice?: number
  downPayment?: number
  downPaymentPercent?: number
  hoaDues?: string
  monthlyHoaPayments?: number
  unit?: string
}

export const formKey = 'financing'

export const FinancingForm = (props: FinancingFormProps) => {
  const { formId } = props
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { speakText } = useUneeqAvatar()
  const { homeType } = useAppSelector(
    getFormValue<HomeInformationFormValues>('homeInformation')
  )
  const condoChosen = homeType === 'condo'
  const toggleOptions = [t('yes'), t('no')]
  const unitOptions = [UnitOptions.Dollars, UnitOptions.Percentage]
  const questions = useCreateSuggestedQuestions('reduceDownPayment')
  const formData = useAppSelector(getFormValue<FinancingFormValues>(formKey))
  const [unit, setUnit] = useState(UnitOptions.Dollars)

  const initialValues: FinancingFormValues = {
    purchasePrice: formData?.purchasePrice,
    downPayment: formData?.downPayment,
    monthlyHoaPayments: formData?.monthlyHoaPayments,
    unit: unit,
    ...(condoChosen && { hoaDues: formData?.hoaDues || t('yes') }),
  }

  const validationSchema = Yup.object().shape({
    purchasePrice: Yup.number()
      .typeError(t('mustBeNumber'))
      .min(0, t('minimumNumber'))
      .required(t('required')),
    downPayment: Yup.number()
      .typeError(t('mustBeNumber'))
      .min(0, t('minimumNumber'))
      .when('unit', {
        is: (unit: string) => unit === UnitOptions.Percentage,
        then: Yup.number().typeError(t('mustBeNumber')).max(100, t('forms.propertyFinancing.maximumValue')),
        otherwise: Yup.number().typeError(t('mustBeNumber')),
      })
      .required(t('required')),
    monthlyHoaPayments: Yup.number()
      .typeError(t('mustBeNumber'))
      .when('hoaDues', {
        is: (hoaDues: any) => hoaDues === t('yes'),
        then: Yup.number().typeError(t('mustBeNumber')).required(t('required')),
        otherwise: Yup.number().typeError(t('mustBeNumber')),
      }),
    ...(condoChosen && { hoaDues: Yup.string().required(t('required')) }),
  })

  const transformValues = (values: typeof initialValues) => {
    const {
      hoaDues,
      monthlyHoaPayments,
      downPayment,
      purchasePrice,
      ...restValues
    } = values

    return {
      monthlyHoaPayments: hoaDues === t('yes') ? monthlyHoaPayments : undefined,
      hoaDues,
      downPayment,
      purchasePrice,
      downPaymentPercent:
        unit === UnitOptions.Dollars
          ? Math.round((downPayment || 0) / (purchasePrice || 1))
          : Math.round(downPayment || 0) / 100,
      ...restValues,
    }
  }

  const onSubmit = (values: typeof initialValues) => {
    dispatch(setForm({ formKey, values: transformValues(values) }))
    navigate(CREDIT_SCORE)
  }
  const formik = useFormik({ initialValues, validationSchema, onSubmit })

  const handleUnitSelect = (event: any) => {
    const value = event.target.value
    setUnit(value)
    formik.values.unit = value
  }

  const handleCalculatorOpen = () => {
    navigate(MORTGAGE_CALCULATOR)
  }

  return (
    <FormikProvider value={formik}>
      <>
        <FormErrorListener onSubmitError={() => speakText(t('formError'))} />
        <Form id={formId} style={{ width: '100%' }}>
          <Box sx={formFill}>
            <Field
              component={TextField}
              name="purchasePrice"
              label={t('forms.propertyFinancing.purchasePriceLabel')}
              placeholder={t('numberPlaceholder')}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              type="text"
            />
            <Field
              component={TextField}
              suggestedQuestionsProps={{ questions }}
              name="downPayment"
              label={t('forms.propertyFinancing.downPaymentLabel')}
              placeholder={t('numberPlaceholder')}
              inputStyles={{ pr: 1, py: 1 }}
              startAdornment={
                unit === UnitOptions.Dollars ? (
                  <InputAdornment position="start">{unit}</InputAdornment>
                ) : (
                  <></>
                )
              }
              endAdornment={
                <InputAdornment position="end">
                  {unitOptions.map((option) => {
                    const isSelected = option === unit
                    return (
                      <Button
                        key={option}
                        value={option}
                        sx={unitToggle}
                        onClick={handleUnitSelect}
                        variant={isSelected ? 'contained' : 'plain'}
                      >
                        <Typography sx={{ pointerEvents: 'none' }}>
                          {option}
                        </Typography>
                      </Button>
                    )
                  })}
                </InputAdornment>
              }
              type="text"
            />
            {condoChosen && (
              <Field
                name="hoaDues"
                component={Toggle}
                toggleOptions={toggleOptions.map((option) => ({
                  id: option,
                  text: option,
                }))}
                label={t('forms.propertyFinancing.hoaDuesLabel')}
                buttonSize="half"
              />
            )}
            {formik.values.hoaDues === t('yes') && (
              <Field
                component={TextField}
                name="monthlyHoaPayments"
                label={t('forms.propertyFinancing.monthlyHoaPaymentsLabel')}
                placeholder={t('numberPlaceholder')}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                type="text"
              />
            )}
            <OpenCalculatorButton handleCalculatorOpen={handleCalculatorOpen} />
          </Box>
        </Form>
      </>
    </FormikProvider>
  )
}
