/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import {
  Box,
  Paper,
  Typography,
  SxProps,
  Theme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@mui/material'
import { SuggestedQuestionsProps } from '../popups/SuggestedQuestions'
import { FieldArrayRenderProps } from 'formik'
import { ArrowDropDown } from '@mui/icons-material'
import { InputLabel } from './InputLabel'
import { convertButtonSize } from '../../util/button'

interface FormAccordionProps {
  /**
   * Field array props from formik for parent form context
   */
  fieldProps: FieldArrayRenderProps

  /**
   * Header to display on each accordion which will have the array index appended on
   */
  header?: string

  /**
   * Name of the fieldarray to access from the parent form component
   */
  fieldName: string

  /**
   * Styles to apply for the Accordion Form
   */
  sx?: SxProps<Theme>

  /**
   * Styles to apply to the Accordions Container
   */
  accordionContainerStyles?: SxProps<Theme>

  /**
   * Styles to apply to the container at the bottom of the Accordion Form
   */
  buttonContainerStyles?: SxProps<Theme>

  /**
   * Styles to apply to the container at the bottom of the Accordion Form
   */
  accordionStyles?: SxProps<Theme>

  /**
   * Text  display for button to add more Accordions
   */
  addButtonText: string

  /**
   * Default value to be pushed when new Accordions are added to the array
   */
  defaultValue: any

  /**
   * Label title for the input
   */
  label: React.ReactNode

  /**
   * Props to pass to create suggested questions component
   */
  suggestedQuestionsProps?: SuggestedQuestionsProps

  /**
   * SubForm to be passed down and rendered by each accordion
   */
  subForm: React.ElementType

  /**
   * Props to pass to the SubForm
   */
  subFormProps: any
}

export const AccordionForm = (props: FormAccordionProps) => {
  const {
    fieldProps: { push, remove, name, form },
    suggestedQuestionsProps,
    label,
    subForm: SubForm,
    subFormProps,
    header,
    fieldName,
    addButtonText,
    sx,
    accordionContainerStyles,
    buttonContainerStyles,
    accordionStyles,
    defaultValue,
  } = props
  const { values } = form
  const [expanded, setExpanded] = React.useState<string | false>(
    values[fieldName]?.length ? `${fieldName}[0]` : false
  )

  const addValue = () => {
    push(defaultValue)
    setExpanded(`${fieldName}[${values[fieldName].length}]`) // Length updates after push
  }

  const handleChange = (accordionName: string) => {
    setExpanded(accordionName !== expanded ? accordionName : false)
  }

  const handleDelete = (index: number) => {
    // Check if theres only one left?
    remove(index)
  }

  return (
    <Box sx={sx}>
      <Box>
        <InputLabel
          htmlFor={name}
          sx={{ display: 'flex', overflow: 'visible' }}
          label={label}
          suggestedQuestionsProps={suggestedQuestionsProps}
        />
      </Box>

      {values[fieldName]?.length ? (
        <Box
          sx={{
            overflow: 'auto',
            background: 'white',
            ...accordionContainerStyles,
          }}
        >
          {values[fieldName].map((_: any, index: number) => {
            const fieldArrayValueName = `${fieldName} ${index + 1}` // Index at 1 for user clarity
            const fieldPath = `${fieldName}[${index}]` // Formik Array value name to access the correct array value

            return (
              <Accordion
                key={fieldArrayValueName}
                expanded={expanded === fieldPath}
                onChange={() => handleChange(fieldPath)}
                sx={accordionStyles}
                square
              >
                <AccordionSummary
                  aria-controls={`${fieldArrayValueName}-content`}
                  id={`${fieldArrayValueName}-header`}
                  expandIcon={<ArrowDropDown />}
                >
                  <Typography>{`${header} ${index + 1}`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <SubForm
                    fieldPath={fieldPath}
                    onDelete={() => handleDelete(index)}
                    form={form}
                    {...subFormProps}
                  />
                </AccordionDetails>
              </Accordion>
            )
          })}
        </Box>
      ) : (
        <></>
      )}

      <Paper
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          px: 6,
          py: 4,
          ...buttonContainerStyles,
        }}
        square
      >
        <Button
          onClick={() => {
            addValue()
          }}
          variant="contained"
          sx={{ height: convertButtonSize('full') }}
        >
          {addButtonText}
        </Button>
      </Paper>
    </Box>
  )
}
