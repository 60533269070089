import { RootState } from '../store'

export const getForm = (state: RootState) => state.form.data

export const getFlattenedForm = (state: RootState) => {
  let result = {}
  const data = state.form.data
  for (const key of Object.keys(data)) {
    result = { ...result, ...data[key] }
  }
  return result
}

export const getFormValue =
  <FormData>(formKey: string) =>
  (state: RootState): FormData => {
    return state.form.data[formKey] as unknown as FormData
  }
