/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import * as Yup from 'yup'
import { Formik, Form, FieldArray } from 'formik'
import { getFormValue, setForm } from '../store/form'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { useNavigate } from 'react-router-dom'
import { INCOME_SOURCES } from '../routes'
import { AccordionForm } from '../components/inputs/AccordionForm'
import { HomeSubForm } from './subForms/HomeSubForm'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { FormErrorListener } from '../components/form/FormErrorListener'
import { useUneeqAvatar } from '../hooks/uneeq'

interface HomeDetailsFormProps {
  formId: string
}

export interface HomeDetail {
  homeUse?: string
  monthlyHomePayment?: number
  monthlyRentalIncome?: number
  rentOrSell?: string
}

export interface HomeDetailsFormValues {
  homeDetails: HomeDetail[]
}

export const formKey = 'homeDetails'

export const HomeDetailsForm = (props: HomeDetailsFormProps) => {
  const { formId } = props
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { speakText } = useUneeqAvatar()
  const formData = useAppSelector(getFormValue<HomeDetailsFormValues>(formKey))

  const defaultHomeDetail: HomeDetail = {
    homeUse: undefined,
    monthlyHomePayment: undefined,
    monthlyRentalIncome: undefined,
    rentOrSell: t('sell'),
  }

  const initialValues: HomeDetailsFormValues = {
    homeDetails: formData?.homeDetails || [defaultHomeDetail],
  }

  const validationSchema = Yup.object({
    homeDetails: Yup.array().of(
      Yup.object().shape({
        homeUse: Yup.string().required(t('required')).nullable(),
        monthlyHomePayment: Yup.number()
          .typeError(t('mustBeNumber'))
          .required(t('required')),
        rentOrSell: Yup.string().when('homeUse', {
          is: (homeUse: any) => homeUse !== t('rental'),
          then: Yup.string().required(t('required')),
          otherwise: Yup.string(),
        }),
        monthlyRentalIncome: Yup.number().when(['homeUse', 'rentOrSell'], {
          is: (homeUse: any, rentOrSell: any) => {
            return homeUse === t('rental') || rentOrSell === t('rent')
          },
          then: Yup.number()
            .typeError(t('mustBeNumber'))
            .required(t('required')),
          otherwise: Yup.number().typeError(t('mustBeNumber')),
        }),
      })
    ),
  })

  const transformValues = (values: typeof initialValues) => {
    if (!values.homeDetails) return {}
    return {
      homeDetails: values.homeDetails.map((homeDetail) => {
        const { rentOrSell, monthlyRentalIncome, homeUse, ...restHomeDetail } =
          homeDetail
        const isRental = homeUse === t('rental')
        const isRent = rentOrSell === t('rent')

        return {
          homeUse,
          rentOrSell: !isRental ? rentOrSell : undefined,
          monthlyRentalIncome:
            isRental || isRent ? monthlyRentalIncome : undefined,
          ...restHomeDetail,
        }
      }),
    }
  }

  const onSubmit = (values: typeof initialValues) => {
    dispatch(
      setForm({
        formKey,
        values: transformValues(values),
      })
    )
    navigate(INCOME_SOURCES)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <>
        <FormErrorListener onSubmitError={() => speakText(t('formError'))} />
        <Form id={formId} style={{ width: '100%', maxWidth: '560px' }}>
          <FieldArray
            name="homeDetails"
            render={(props) => {
              return (
                <AccordionForm
                  label={t('forms.homeDetails.homeDetailsLabel')}
                  fieldProps={props}
                  subForm={HomeSubForm}
                  subFormProps={{
                    deleteButtonText: t('forms.homeDetails.removeHome'),
                  }}
                  header={t('forms.homeDetails.home')}
                  fieldName="homeDetails"
                  addButtonText={t('forms.homeDetails.addHome')}
                  defaultValue={defaultHomeDetail}
                  accordionContainerStyles={{
                    maxHeight: '500px',
                    borderRadius: '4px 4px 0 0',
                  }}
                  buttonContainerStyles={{
                    borderRadius: '0 0 4px 4px',
                    position: 'relative',
                    top: '1px',
                  }}
                />
              )
            }}
          />
        </Form>
      </>
    </Formik>
  )
}
