import * as R from 'ramda'
import { formKey as homeDetailsKey, HomeDetail } from '../forms/HomeDetailsForm'
import {
  formKey as incomeSourcesKey,
  IncomeSource,
} from '../forms/IncomeSourcesForm'
import { formKey as liabilitiesKey, Liability } from '../forms/LiabilitiesForm'
import { formKey as financingKey } from '../forms/FinancingForm'
import { FormSlice } from '../store/form'
import axios from 'axios'
import { config } from '../config'
import { formKey as homeInformationKey } from '../forms/HomeInformationForm'
import {
  MortgageCalculatorValues,
  Units,
  UnitOptions,
  MortgageResult,
} from '../schema/mortgageCalculator'

export const calculateLoanAmount = (
  purchasePrice: number,
  downPaymentPercent: number
) => purchasePrice * (1 - downPaymentPercent)

const getHomeMonthlyRentalIncome = (homeDetail: HomeDetail) =>
  R.pathOr(0, ['monthlyRentalIncome'], homeDetail) *
  config.defaultRates.rentalIncomeRateReduction()

const convertIncomeToMonthly =
  (paymentField: string, frequencyField: string, yearly: string) =>
  (incomeSource: IncomeSource) => {
    const payment = R.pathOr(0, [paymentField], incomeSource)
    const frequency = R.pathOr('', [frequencyField], incomeSource)

    return R.cond([
      [() => frequency === yearly, () => payment / 12],
      [R.T, () => payment],
    ])()
  }

const calculatePropertyTaxPayments = (
  purchasePrice: number,
  state: string | undefined
) => (purchasePrice * config.defaultRates.statePropertyTaxRate(state)) / 12

const reduce = R.reduce<number, number>(R.add, 0)

const calculateMortgageInsurance = (
  loanAmount: number,
  downPaymentPercent: number
) =>
  R.cond([
    [
      R.gt(config.defaultRates.mortgageInsuranceThreshold()),
      R.always((loanAmount * config.defaultRates.mortgageInsurance()) / 12),
    ],
    [R.T, R.always(0)],
  ])(downPaymentPercent)

const calculateMonthlyMortgagePayment = (
  loanAmount: number,
  interestRate: number,
  term: number
) => {
  const monthlyInterestRate = interestRate / 12
  const totalLoanPayments = term * 12

  const interestOverTerm = Math.pow(1 + monthlyInterestRate, totalLoanPayments)
  return (
    (loanAmount * (monthlyInterestRate * interestOverTerm)) /
    (interestOverTerm - 1)
  )
}

export const getInterestRate = async (sessionIdJwt: string) => {
  try {
    const { data } = await axios.post(
      config.interestRateAPI.url(),
      {
        productKey: config.interestRateAPI.productKey(),
      },
      { headers: { Authorization: `Bearer ${sessionIdJwt}` } }
    )

    const { interestRate } = data
    return interestRate || config.defaultRates.interestRate()
  } catch (_) {
    return config.defaultRates.interestRate()
  }
}

export const calculateDebtToIncomeRatio = async (
  form: FormSlice['data'],
  yearly: string,
  getInterestRateFn: typeof getInterestRate,
  sessionIdJwt: string
) => {
  const purchasePrice = R.pathOr(0, [financingKey, 'purchasePrice'], form)
  const downPaymentPercent = R.pathOr(
    0,
    [financingKey, 'downPaymentPercent'],
    form
  )
  const incomeSources = R.pathOr<IncomeSource[]>(
    [],
    [incomeSourcesKey, 'incomeSources'],
    form
  )
  const homeDetails = R.pathOr<HomeDetail[]>(
    [],
    [homeDetailsKey, 'homeDetails'],
    form
  )
  const liabilities = R.pathOr<Liability[]>(
    [],
    [liabilitiesKey, 'liabilities'],
    form
  )
  const loanAmount = calculateLoanAmount(purchasePrice, downPaymentPercent)

  const state = R.path<string | undefined>(
    [homeInformationKey, 'countyState', 'state_abbr'],
    form
  )
  const monthlyBasePays = R.map(
    convertIncomeToMonthly('incomeBasePay', 'incomeBaseFrequency', yearly),
    incomeSources
  )
  const monthlyBonusPays = R.map(
    convertIncomeToMonthly('incomeBonusPay', 'incomeBonusFrequency', yearly),
    incomeSources
  )
  const monthlyRentalIncomes = R.map(getHomeMonthlyRentalIncome, homeDetails)

  const totalMonthlyIncomes = [
    reduce(monthlyBasePays),
    reduce(monthlyBonusPays),
    reduce(monthlyRentalIncomes),
  ]

  const monthlyRentalPayments = R.map(
    R.pathOr(0, ['monthlyHomePayment']),
    homeDetails
  )
  const monthlyLiabilities = R.map(
    R.pathOr(0, ['liabilityMonthlyPayment']),
    liabilities
  )

  const interestRate = await getInterestRateFn(sessionIdJwt)

  const totalMonthlyDebtObligations = [
    reduce(monthlyRentalPayments),
    reduce(monthlyLiabilities),
    calculatePropertyTaxPayments(purchasePrice, state),
    calculateMortgageInsurance(loanAmount, downPaymentPercent),
    calculateMonthlyMortgagePayment(loanAmount, interestRate, 30),
  ]

  const totalMonthlyIncome = reduce(totalMonthlyIncomes)
  const totalMonthlyDebtObligation = reduce(totalMonthlyDebtObligations)

  return totalMonthlyDebtObligation / (totalMonthlyIncome || 1)
}

export const calculateMortgageResults = (
  values: MortgageCalculatorValues,
  units: Units
): MortgageResult[][] => {
  const {
    hoaFees = 0,
    pmi = 0,
    propertyTax = 0,
    propertyValue = 0,
    homeInsurance = 0,
    mortgageAmount = 0,
    interestRate = 0,
    amortizationPeriod = 0,
  } = values

  return [
    [
      {
        key: 'monthlyPrincipal',
        value: calculateMonthlyMortgagePayment(
          mortgageAmount,
          interestRate / 100,
          convertToYears(amortizationPeriod, units.amortizationPeriodUnit)
        ),
      },
      {
        key: 'propertyTaxes',
        value: calculateMonthlyPayment(
          propertyTax,
          propertyValue,
          units.propertyTaxUnit
        ),
      },
      {
        key: 'homeOwnersInsurance',
        value: calculateMonthlyPayment(
          homeInsurance,
          propertyValue,
          units.homeInsuranceUnit
        ),
      },
    ],
    [
      {
        key: 'privateMortgage',
        value: calculateMonthlyPayment(pmi, propertyValue, units.pmiUnit),
      },
      { key: 'resultsHoaFees', value: Number(hoaFees) },
      { value: 0 },
    ],
  ]
}

const calculateMonthlyPayment = (
  inputField: number,
  propertyValue: number,
  unit: UnitOptions
) =>
  unit === UnitOptions.Dollars
    ? inputField / 12
    : (inputField / 1200) * propertyValue

const convertToYears = (term: number, unit: UnitOptions) =>
  unit === UnitOptions.Year ? term : term / 12
