import React from 'react'
import { PersonalDetailsPage } from './pages/PersonalDetailsPage'
import { HomeInformationPage } from './pages/HomeInformationPage'
import { FinalPage } from './pages/FinalPage'
import { FinancingPage } from './pages/FinancingPage'
import { CreditScorePage } from './pages/CreditScorePage'
import { HomeDetailsPage } from './pages/HomeDetailsPage'
import { IncomeSourcesPage } from './pages/IncomeSourcesPage'
import { AssetsPage } from './pages/AssetsPage'
import { LiabilitiesPage } from './pages/LiabilitiesPage'
import { PhoneNumberPage } from './pages/PhoneNumberPage'
import { LoadingFinalPage } from './pages/LoadingFinalPage'
import { LocationFailPage } from './pages/LocationFailPage'
import { SoftFailPage } from './pages/SoftFailPage'
import { MortgageCalculatorPage } from './pages/MortgageCalculatorPage'

export const INDEX = '/'
export const HOME_INFORMATION = '/home-information'
export const FINANCING = '/financing'
export const CREDIT_SCORE = '/credit-score'
export const HOME_DETAILS = '/home-details'
export const INCOME_SOURCES = '/income-sources'
export const ASSETS = '/assets'
export const LIABILITIES = '/liabilities'
export const PHONE_NUMBER = '/phone-number'
export const LOADING_RESULTS = '/loading-results'
export const FINAL_PAGE = '/final-page'
export const MORTGAGE_CALCULATOR = '/calculator'

export const LOCATION_FAIL = '/location-fail'
export const SOFT_FAIL = '/soft-fail'

export const progressRoutes = [
  { path: INDEX, element: <PersonalDetailsPage /> },
  { path: HOME_INFORMATION, element: <HomeInformationPage /> },
  { path: FINANCING, element: <FinancingPage /> },
  { path: CREDIT_SCORE, element: <CreditScorePage /> },
  { path: HOME_DETAILS, element: <HomeDetailsPage /> },
  { path: INCOME_SOURCES, element: <IncomeSourcesPage /> },
  { path: ASSETS, element: <AssetsPage /> },
  { path: LIABILITIES, element: <LiabilitiesPage /> },
  { path: PHONE_NUMBER, element: <PhoneNumberPage /> },
  { path: LOADING_RESULTS, element: <LoadingFinalPage /> },
  { path: FINAL_PAGE, element: <FinalPage /> },
  { path: MORTGAGE_CALCULATOR, element: <MortgageCalculatorPage />}
]

const otherRoutes = [
  { path: LOCATION_FAIL, element: <LocationFailPage /> },
  { path: SOFT_FAIL, element: <SoftFailPage /> },
]

const routeToPathArray = (
  routes: {
    path: string
    element: JSX.Element
  }[]
) => routes.map((route) => route.path)

export const progressRoutesPaths = routeToPathArray(progressRoutes)
export const otherRoutesPaths = routeToPathArray(otherRoutes)
export const routes = [...progressRoutes, ...otherRoutes]
