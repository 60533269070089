/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Field } from 'formik'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { Box, Button, InputAdornment } from '@mui/material'
import { TextField } from '../../components/inputs/TextField'
import { SubFormProps } from '../../types'
import { Autocomplete } from '../../components/inputs/Autocomplete'

export const LiabilitiesSubForm = (props: SubFormProps) => {
  const { fieldPath, onDelete, deleteButtonText } = props
  const { t } = useTranslation()

  const dropdownOptions = [
    t('forms.liabilities.instalmentLoans'),
    t('forms.liabilities.childSupport'),
    t('forms.liabilities.revolving'),
    t('forms.liabilities.educationLoans'),
    t('forms.liabilities.alimony'),
    t('other'),
  ]
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box>
        <Field
          component={Autocomplete}
          name={`${fieldPath}.liabilityType`}
          label={t('forms.liabilities.liabilityTypeLabel')}
          placeholder={t('chooseOption')}
          options={dropdownOptions}
        />
      </Box>

      <Box>
        <Field
          name={`${fieldPath}.liabilityMonthlyPayment`}
          component={TextField}
          label={t('forms.liabilities.liabilityMonthlyPaymentLabel')}
          placeholder={t('numberPlaceholder')}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          type="text"
        />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onDelete} variant="contained" color="error">
          {deleteButtonText}
        </Button>
      </Box>
    </Box>
  )
}
