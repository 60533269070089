import React, { FormEvent } from 'react'
import { useEffect } from 'react'
import {
  handleSessionStateUpdate,
  handleUneeqEvent,
  selectSessionState,
} from '../../store/uneeq'
import { SessionState } from '../../types/uneeq'
import { config } from '../../config'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'

window.uneeqInteractionsOptions = {
  personaShareId: config.uneeq.shareId(),
  layoutMode: 'splitScreen',
  position: 'left',
  enableTransparentBackground: true,
  mobileViewWidthBreakpoint: 1,
  playWelcome: true,
  verboseLogging: true,
  renderContent: true,
  showUserInputInterface: true,
}

interface UneeqSessionProviderProps {
  /**
   * The landing page to show before the session
   * has been started. Should contain a button that
   * will submit the form to start the session
   */
  landingPage: React.ReactNode

  /**
   * The loading page to show whilst a session
   * is being started
   */
  loadingPage: React.ReactNode

  /**
   * The children to display when a session is active
   */
  children: React.ReactNode
}

export const UneeqSessionProvider = ({
  landingPage,
  loadingPage,
  children,
}: UneeqSessionProviderProps) => {
  const { uneeqStartSession } = window

  const sessionState = useAppSelector(selectSessionState)
  const dispatch = useAppDispatch()

  const isAvatarLoading = sessionState === SessionState.LOADING_AVATAR
  const isSessionLive = sessionState === SessionState.AVATAR_LIVE
  const sessionNotLive = !isSessionLive

  useEffect(() => {
    const onSessionStateUpdate = (event: Event) =>
      dispatch(handleSessionStateUpdate(event))

    const onUneeqEvent = (event: Event) => dispatch(handleUneeqEvent(event))

    window.addEventListener('uneeqSessionStateUpdate', onSessionStateUpdate)
    window.addEventListener('uneeqEvent', onUneeqEvent)

    // Cleanup
    return () => {
      window.removeEventListener(
        'uneeqSessionStateUpdate',
        onSessionStateUpdate
      )
      window.removeEventListener('uneeqEvent', onUneeqEvent)
    }
  }, [dispatch])

  const startSession = (event: FormEvent) => {
    event.preventDefault()
    uneeqStartSession()
  }

  if (sessionNotLive && isAvatarLoading) {
    return <>{loadingPage}</>
  }

  if (sessionNotLive) {
    return (
      <form onSubmit={startSession} style={{ height: '100%', width: '100%' }}>
        {landingPage}
      </form>
    )
  }

  return <>{children}</>
}
