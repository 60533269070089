import React from 'react'
import {
  InputLabel as MuiInputLabel,
  InputLabelProps as MuiLabelProps,
} from '@mui/material'
import {
  SuggestedQuestions,
  SuggestedQuestionsProps,
} from '../popups/SuggestedQuestions'

interface InputLabelProps extends MuiLabelProps {
  /**
   * Label title for the input
   */
  label: React.ReactNode

  /**
   * Props to pass to create suggested questions component
   */
  suggestedQuestionsProps?: SuggestedQuestionsProps
}

export const InputLabel = (props: InputLabelProps) => {
  const { label, suggestedQuestionsProps, ...otherProps } = props

  return (
    <MuiInputLabel {...otherProps}>
      {label}
      {suggestedQuestionsProps && (
        <SuggestedQuestions {...suggestedQuestionsProps} />
      )}
    </MuiInputLabel>
  )
}
