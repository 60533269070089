/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
  FormControl,
  Input,
  SxProps,
  TextFieldProps as MuiTextFieldProps,
  Theme,
} from '@mui/material'
import { SuggestedQuestionsProps } from '../popups/SuggestedQuestions'
import { FieldProps } from 'formik'
import { InputWrapper } from './InputWrapper'

type TextFieldProps = Omit<MuiTextFieldProps, 'variant'> &
  FieldProps & {
    /**
     * Styling for the input
     */
    inputStyles?: SxProps<Theme>

    labelStyles?: SxProps<Theme>

    /**
     * Props to pass to create suggested questions component
     */
    suggestedQuestionsProps?: SuggestedQuestionsProps

    /**
     * Adornment to add at the start of the input
     */
    startAdornment?: React.ReactNode

    /**
     * Adornment to add at the end of the input
     */
    endAdornment?: React.ReactNode
  }

export const TextField = (props: TextFieldProps) => {
  const {
    label,
    suggestedQuestionsProps,
    error,
    id,
    placeholder,
    inputStyles,
    type,
    startAdornment,
    endAdornment,
    labelStyles,
    field: { name, value },
    form: { errors, touched, handleChange, handleBlur },
  } = props
  return (
    <FormControl fullWidth variant="standard">
      <InputWrapper
        name={name}
        label={label}
        suggestedQuestionsProps={suggestedQuestionsProps}
        touched={touched}
        errors={errors}
        labelStyles={labelStyles}
      >
        <Input
          type={type}
          id={name || id}
          aria-describedby={`${name}-error`}
          placeholder={placeholder || name || id}
          value={value || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!error}
          sx={inputStyles}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
        />
      </InputWrapper>
    </FormControl>
  )
}
