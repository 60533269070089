import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ASSETS,
  CREDIT_SCORE,
  FINAL_PAGE,
  FINANCING,
  HOME_DETAILS,
  HOME_INFORMATION,
  INCOME_SOURCES,
  INDEX,
  LIABILITIES,
  LOADING_RESULTS,
  otherRoutesPaths,
  PHONE_NUMBER,
  MORTGAGE_CALCULATOR
} from '../../routes'
import { useAppSelector } from '../../hooks/hooks'
import { getForm } from '../../store/form'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
interface RouteMiddlewareProps {
  /**
   * The children to display when a session is active
   */
  children: React.ReactNode
}

export const RouteMiddleware = ({ children }: RouteMiddlewareProps) => {
  const navigate = useNavigate()
  const pathName = window.location.pathname
  const form = useAppSelector(getForm)
  const { t } = useTranslation()

  useEffect(() => {
    if (otherRoutesPaths.includes(pathName)) return

    const allowedRoutes = [INDEX]

    // Check Personal Details State to allow Home Information
    if (form['personalDetails']) allowedRoutes.push(HOME_INFORMATION)

    // Check Property Details State to allow Property Financing
    // Allow user to go to calculator from financing form
    if (form['homeInformation']) {
      allowedRoutes.push(FINANCING)
      allowedRoutes.push(MORTGAGE_CALCULATOR)
    }

    // Check Property Financing State to allow Credit Score
    if (form['financing']) {
      allowedRoutes.push(CREDIT_SCORE)
    }

    // Check Credit Score to allow Home Details
    // Credit Score needs to have Home to allow Home Details
    if (form['creditScore']?.ownHome === t('yes'))
      allowedRoutes.push(HOME_DETAILS)

    // Check Credit Score to allow Income Sources
    // Home Details Can be filled OR Credit Score can not have home
    if (form['homeDetails'] || form['creditScore']?.ownHome === t('no'))
      allowedRoutes.push(INCOME_SOURCES)

    // Check Income Sources to allow Assets
    if (form['incomeSources']) allowedRoutes.push(ASSETS)

    // Check Assets to allow Liabilities
    if (form['assets']) allowedRoutes.push(LIABILITIES)

    // Check Liabilities to allow Phone Number
    if (form['liabilities']) allowedRoutes.push(PHONE_NUMBER)

    // Check Phone Number to allow Loading
    if (form['phoneNumber']) allowedRoutes.push(LOADING_RESULTS)

    // Check we have information to allow Final Page
    if (allowedRoutes.includes(LOADING_RESULTS)) allowedRoutes.push(FINAL_PAGE)

    if (allowedRoutes.includes(pathName)) return

    // Go to the last allowed page
    navigate(allowedRoutes[allowedRoutes.length - 1])

    // Disabled for performance to stop this rendering on each form submit
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName, navigate, t])
  return <>{children}</>
}
