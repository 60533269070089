import React, { useEffect } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { flexFill } from '../util/sx'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { useNavigate } from 'react-router-dom'
import { convertButtonSize } from '../util/button'
import { SoftFailForm } from '../forms/SoftFailForm'
import { setProgress } from '../store/progressBar'
import { useAppDispatch } from '../hooks/hooks'
import { useUneeqAvatar } from '../hooks/uneeq'

export const SoftFailPage = () => {
  const formId = 'soft-fail-form'
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  useUneeqAvatar(t('pages.softFail.avatarIntro'))

  const pageBack = () => {
    navigate(-1)
  }

  useEffect(() => {
    dispatch(setProgress(100))
  })

  return (
    <Box
      sx={{
        ...flexFill,
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 4,
      }}
    >
      <Box sx={{ maxWidth: 420 }}>
        <Typography sx={{ fontWeight: 'bold' }}>
          {t('forms.softFail.furtherApplicationReview')}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <SoftFailForm formId={formId} />
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          variant="outlined"
          onClick={pageBack}
          sx={{ height: convertButtonSize('full') }}
        >
          {t('back')}
        </Button>
        <Button
          variant="contained"
          form={formId}
          type="submit"
          sx={{ height: convertButtonSize('full') }}
        >
          {t('finish')}
        </Button>
      </Box>
    </Box>
  )
}
