import { createTheme } from '@mui/material/styles'

export const colors = {
  primary: '#45A1E3',
  secondary: '#F3F7FA',
  secondaryText: '#4E5A6D',
  white: '#FFFFFF',
  error: '#C90303',
  success: '#2BA301',
  darkGrey: '#7D7D7D',
  lightGrey: '#F0F0F0',
  backgroundColor: 'linear-gradient(to right, #E0E1E3, #D8DCE1)',
}

const spacing = 4

export const theme = createTheme({
  spacing,
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.error,
    },
    success: {
      main: colors.success,
    },
    text: {
      primary: colors.secondaryText,
    },
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      'Roboto',
      '"Helvetica Neue"',
      '"Helvetica"',
      'Arial',
      'sans-serif',
      '"Segoe UI"',
    ].join(','),
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: colors.secondary,
          border: '1px solid #ced4da',
          fontSize: '1rem',
          padding: '6px 13px',
          marginTop: '1.5rem !important',
          fontWeight: '600',
          height: 40,
          maxWidth: 280,
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        freeSolo: true,
      },
      styleOverrides: {
        listbox: {
          background: colors.secondary,
        },
        option: {
          fontWeight: '600',
        },
        groupLabel: {
          fontWeight: '600',
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
        fullWidth: true,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'plain' },
          style: {
            background: 'none',
            color: colors.secondaryText,
          },
        },
      ],
      defaultProps: {
        disableElevation: true,
        color: 'primary',
      },
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
        containedPrimary: {
          color: colors.white,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          background: colors.white,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors.secondaryText,
          fontSize: '1.25rem',
          fontWeight: '700',
        },
      },
      defaultProps: {
        shrink: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: colors.backgroundColor,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontWeight: 600,
        },
        h5: {
          fontWeight: 700,
        },
        h1: {
          color: colors.primary,
          fontSize: '22px',
          fontWeight: 600
        },
        h2: {
          fontSize: '34px',
          fontWeight: 600
        },
        subtitle1: {
          fontWeight: 400
        }
      },
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: `${3 * spacing}px ${6 * spacing}px  ${6 * spacing}px`,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: `0 ${6 * spacing}px`,
          minHeight: '80px',
        },
        content: {
          margin: `${4 * spacing}px 0`,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        color: colors.secondaryText,
      },
    },
  },
})
