/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import * as Yup from 'yup'
import { Formik, Form, FieldArray } from 'formik'
import { getFormValue, setForm } from '../store/form'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { useNavigate } from 'react-router-dom'
import { LIABILITIES } from '../routes'
import { AccordionForm } from '../components/inputs/AccordionForm'
import { AssetsSubForm } from './subForms/AssetsSubForm'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { useUneeqAvatar } from '../hooks/uneeq'
import { FormErrorListener } from '../components/form/FormErrorListener'

interface AssetsFormProps {
  formId: string
}

interface Asset {
  assetType?: string
  assetValue?: number
}
interface AssetsFormValues {
  assets: Asset[]
}

export const formKey = 'assets'

export const AssetsForm = (props: AssetsFormProps) => {
  const { formId } = props
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { speakText } = useUneeqAvatar()
  const formData = useAppSelector(getFormValue<AssetsFormValues>(formKey))

  const defaultAsset: Asset = {
    assetType: undefined,
    assetValue: undefined,
  }

  const initialValues: AssetsFormValues = {
    assets: formData?.assets || [defaultAsset],
  }

  const validationSchema = Yup.object({
    assets: Yup.array().of(
      Yup.object().shape({
        assetType: Yup.string().required(t('required')).nullable(),
        assetValue: Yup.number()
          .typeError(t('mustBeNumber'))
          .required(t('required')),
      })
    ),
  })

  const onSubmit = (values: typeof initialValues) => {
    dispatch(setForm({ formKey, values }))
    navigate(LIABILITIES)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <>
        <FormErrorListener onSubmitError={() => speakText(t('formError'))} />
        <Form id={formId} style={{ width: '100%', maxWidth: '560px' }}>
          <FieldArray
            name="assets"
            render={(props) => {
              return (
                <AccordionForm
                  label={t('forms.assets.assetsLabel')}
                  fieldProps={props}
                  subForm={AssetsSubForm}
                  subFormProps={{
                    deleteButtonText: t('forms.assets.removeAsset'),
                  }}
                  header={t('forms.assets.asset')}
                  fieldName="assets"
                  addButtonText={t('forms.assets.addAsset')}
                  defaultValue={defaultAsset}
                  accordionContainerStyles={{
                    maxHeight: '500px',
                    borderRadius: '4px 4px 0 0',
                  }}
                  buttonContainerStyles={{
                    borderRadius: '0 0 4px 4px',
                    position: 'relative',
                    top: '1px',
                  }}
                />
              )
            }}
          />
        </Form>
      </>
    </Formik>
  )
}
