import React from 'react'
import { Toggle } from '../components/inputs/Toggle'
import * as Yup from 'yup'
import { Field, Formik, Form } from 'formik'
import { getFormValue, setForm } from '../store/form'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { useNavigate } from 'react-router-dom'
import { FINANCING, LOCATION_FAIL } from '../routes'
import { formFill } from '../util/sx'
import { Box, createFilterOptions, FilterOptionsState } from '@mui/material'
import { CardSelect } from '../components/inputs/CardSelect'
import houseImage from '../assets/single-house.svg'
import { Autocomplete } from '../components/inputs/Autocomplete'
import { useGetCounties, StateCounty } from '../hooks/locations'
import { useCreateSuggestedQuestions } from '../hooks/suggestedQuestions'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { allowedStates } from '../util/allowedStatesList'
import { FormErrorListener } from '../components/form/FormErrorListener'
import { useUneeqAvatar } from '../hooks/uneeq'

interface HomeInformationFormProps {
  formId: string
}

export interface HomeInformationFormValues {
  homeType?: string
  countyState?: StateCounty
  vaLoanEligable?: string
}

const houseValues = [
  {
    title: 'Single Family Home',
    imgUrl: houseImage,
    id: 'singleHome',
  },
  {
    title: 'Multi Unit Home',
    imgUrl: houseImage,
    id: 'multiUnitHome',
  },
  {
    title: 'Condo',
    imgUrl: houseImage,
    id: 'condo',
  },
]

export const formKey = 'homeInformation'

export const HomeInformationForm = (props: HomeInformationFormProps) => {
  const { formId } = props
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { speakText } = useUneeqAvatar()
  const toggleOptions = [t('yes'), t('no')]
  const stateCounties = useGetCounties()
  const questions = useCreateSuggestedQuestions('vaLoans')
  const formData = useAppSelector(
    getFormValue<HomeInformationFormValues>(formKey)
  )
  const defaultFilterOptions = createFilterOptions()

  const filterOptions = (
    options: unknown[],
    state: FilterOptionsState<unknown>
  ) => {
    return defaultFilterOptions(options, state).slice(0, 100)
  }

  const createOptionsLabel = (stateCounty: StateCounty) => {
    if (!stateCounty.county || !stateCounty.state) return ''
    return `${stateCounty.county}, ${stateCounty.state}`
  }

  const initialValues: HomeInformationFormValues = {
    homeType: formData?.homeType,
    countyState: formData?.countyState,
    vaLoanEligable: formData?.vaLoanEligable || toggleOptions[1],
  }

  const validationSchema = Yup.object({
    homeType: Yup.string().required(t('required')),
    countyState: Yup.object().required(t('required')).nullable(),
    vaLoanEligable: Yup.string().required(t('required')),
  })

  const onSubmit = (values: typeof initialValues) => {
    dispatch(setForm({ formKey, values }))
    if (
      values.countyState?.state_abbr &&
      allowedStates.includes(values.countyState.state_abbr)
    ) {
      navigate(FINANCING)
      return
    }
    navigate(LOCATION_FAIL)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <>
        <FormErrorListener onSubmitError={() => speakText(t('formError'))} />
        <Form id={formId} style={{ width: '100%' }}>
          <Box sx={formFill}>
            <Field
              name="homeType"
              component={CardSelect}
              cardList={houseValues}
              label={t('forms.homeInformation.homeSelectLabel')}
              cardStyles={{
                flexDirection: 'column',
                width: 180,
                height: 160,
                p: 2,
              }}
            ></Field>

            <Field
              component={Autocomplete}
              name="countyState"
              label={t('forms.homeInformation.countyStateLabel')}
              placeholder={t('chooseOption')}
              getOptionLabel={createOptionsLabel}
              options={stateCounties}
              groupBy={(options: StateCounty) => options.state}
              filterOptions={filterOptions}
            />

            <Field
              name="vaLoanEligable"
              component={Toggle}
              toggleOptions={toggleOptions.map((option) => ({
                id: option,
                text: option,
              }))}
              suggestedQuestionsProps={{ questions }}
              label={t('forms.homeInformation.vaEligibleLabel')}
              buttonSize="half"
            ></Field>
          </Box>
        </Form>
      </>
    </Formik>
  )
}
