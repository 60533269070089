import React, { useEffect, useState } from 'react'
import { Button, Box, Typography } from '@mui/material'
import { setProgressValueByRoute } from '../store/progressBar'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { getFormValue, resetForm } from '../store/form'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { PDFDocument } from 'pdf-lib'
import {
  PersonalDetailsFormValues,
  personalDetailsKey,
} from '../forms/PersonalDetailsForm'
import {
  FinancingFormValues,
  formKey as financingKey,
} from '../forms/FinancingForm'
import {
  formKey as loadingFinalPageKey,
  LoadingFinalPageFormValues,
} from '../pages/LoadingFinalPage'
import download from 'downloadjs'
import pdf from '../assets/pre-qualification-template.pdf'
import { flexFill } from '../util/sx'
import { convertButtonSize } from '../util/button'
import { useNavigate } from 'react-router-dom'
import { INDEX, PHONE_NUMBER } from '../routes'
import { calculateLoanAmount } from '../util/calculations'
import { useUneeqAvatar } from '../hooks/uneeq'

export const FinalPage = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const personalDetailsData = useAppSelector(
    getFormValue<PersonalDetailsFormValues>(personalDetailsKey)
  )
  const financingData = useAppSelector(
    getFormValue<FinancingFormValues>(financingKey)
  )
  const { debtToIncomeRatio } =
    useAppSelector(
      getFormValue<LoadingFinalPageFormValues>(loadingFinalPageKey)
    ) || {}

  const [purchasePrice, setPurchasePrice] = useState(0)
  const [downPayment, setDownPayment] = useState(0)
  const [loanAmount, setLoanAmount] = useState(0)
  const [isEligibleForLoan, setIsEligibleForLoan] = useState(false)
  const navigate = useNavigate()
  const { speakText } = useUneeqAvatar()

  useEffect(() => {
    if (!debtToIncomeRatio) return

    const purchasePrice = financingData?.purchasePrice || 0
    const downPaymentPercent = financingData?.downPaymentPercent || 0
    const loanAmount = calculateLoanAmount(purchasePrice, downPaymentPercent)

    setPurchasePrice(purchasePrice)
    setDownPayment(downPaymentPercent * 100)
    setLoanAmount(loanAmount)
    const isEligibleForLoan = debtToIncomeRatio < 0.43
    setIsEligibleForLoan(isEligibleForLoan)

    if (!personalDetailsData) {
      return
    }

    const text = isEligibleForLoan
      ? t('pages.finalPage.avatarApproval', {
          loanAmount,
          downPayment: downPaymentPercent * 100,
          name: personalDetailsData?.firstName,
        })
      : t('pages.finalPage.avatarRejection')

    speakText(text)
  }, [financingData, debtToIncomeRatio, speakText, t, personalDetailsData])

  useEffect(() => {
    dispatch(setProgressValueByRoute())
  }, [dispatch])

  const restart = () => {
    dispatch(resetForm())
    navigate(INDEX)
  }

  const back = () => {
    navigate(PHONE_NUMBER)
  }

  const downloadForm = async () => {
    // Fetch the PDF with form fields
    const formPdfBytes = await fetch(pdf).then((res) => res.arrayBuffer())

    const pdfDoc = await PDFDocument.load(formPdfBytes)

    const page = pdfDoc.getPage(0)
    const form = pdfDoc.getForm()

    // Get Form Fields
    const dateField = form.getTextField('currentDate')
    const fullNameField = form.getTextField('borrowerFullName')
    const name = `${personalDetailsData?.firstName} ${personalDetailsData?.lastName}`

    // Set values on Form fields
    dateField.setText(new Date().toLocaleDateString('en-US'))
    fullNameField.setText(name)

    // Draw text onto the form
    page.drawText(t('pages.finalPage.qualificationDetails', {
      loanAmount,
      purchasePrice,
      downPayment,
      name,
    }), {
      x: 62,
      y: 580,
      size: 14
    })

    // Flatten the form to make sure forms are set
    form.flatten()

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save()

    // Trigger the browser to download the PDF document
    download(pdfBytes, 'instamortgage-pre-qualification.pdf', 'application/pdf')
  }

  return (
    <Box
      sx={{
        ...flexFill,
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 6,
      }}
    >
      <Box>
        <Typography>
          {isEligibleForLoan
            ? t('pages.finalPage.approvalText', {
                loanAmount,
                downPayment,
              })
            : t('pages.finalPage.rejectionText', {
                loanAmount,
                downPayment,
              })}
        </Typography>
      </Box>
      {isEligibleForLoan && (
        <Box>
          <Button
            variant="contained"
            onClick={() => {
              downloadForm()
            }}
            sx={{ height: convertButtonSize('full') }}
          >
            {t('pages.finalPage.downloadPdf')}
          </Button>
        </Box>
      )}
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          variant="outlined"
          onClick={back}
          sx={{ height: convertButtonSize('full') }}
        >
          {t('back')}
        </Button>
        <Button
          variant="contained"
          onClick={restart}
          sx={{ height: convertButtonSize('full') }}
        >
          {t('pages.finalPage.restartText')}
        </Button>
      </Box>
    </Box>
  )
}
