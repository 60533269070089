import { uneeqSlice } from './slice'
import { Dispatch } from '@reduxjs/toolkit'
import {
  AnswerEventDetail,
  QuestionTextEventDetail,
  SessionState,
  UneeqEvent,
  UneeqMessageType,
  UneeqSessionStateEvent,
  UneeqSessionStateValue,
} from '../../types/uneeq'
import { safeJSONParse } from '../../util/json'

const {
  setSessionState,
  setSentQuestion,
  setAvatarSpeech,
  setSessionIdJwt,
  setAvatarSpeaking,
} = uneeqSlice.actions

export const handleUneeqEvent = (event: Event) => (dispatch: Dispatch) => {
  const { detail } = event as UneeqEvent
  const { uneeqMessageType } = detail

  if (uneeqMessageType === UneeqMessageType.QUESTION_TEXT) {
    const { question } = detail as QuestionTextEventDetail

    // Question from user
    return dispatch(setSentQuestion(question))
  }

  if (uneeqMessageType === UneeqMessageType.ANSWER) {
    const { answerAvatar, answer: speech } = detail as AnswerEventDetail
    const { instructions } = safeJSONParse(answerAvatar)

    const { sessionIdJwt } = instructions?.customData ?? {}

    // Token for speak API included in WELCOME message
    if (sessionIdJwt) {
      dispatch(setSessionIdJwt(sessionIdJwt))
    }

    return dispatch(setAvatarSpeech(speech))
  }

  if (uneeqMessageType === UneeqMessageType.READY) {
    return dispatch(setSessionState(SessionState.SDK_READY))
  }

  if (uneeqMessageType === UneeqMessageType.STARTED_SPEAKING) {
    return dispatch(setAvatarSpeaking(true))
  }

  if (uneeqMessageType === UneeqMessageType.FINISHED_SPEAKING) {
    return dispatch(setAvatarSpeaking(false))
  }
}

export const handleSessionStateUpdate =
  (event: Event) => (dispatch: Dispatch) => {
    const { detail } = event as UneeqSessionStateEvent
    const { state, live } = detail

    if (state === UneeqSessionStateValue.WAITING_TO_START) {
      return dispatch(setSessionState(SessionState.LOADING_AVATAR))
    }

    return dispatch(
      setSessionState(live ? SessionState.AVATAR_LIVE : SessionState.SDK_READY)
    )
  }

export const restartSessionState = () => {
  const { uneeqEndSession } = window
  uneeqEndSession()
}
