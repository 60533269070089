import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { formSlice, formSessionStorageMiddleware } from './form'
import { progressBarSlice } from './progressBar'
import { uneeqSlice } from './uneeq'

export const reducer = {
  form: formSlice.reducer,
  progressBar: progressBarSlice.reducer,
  uneeq: uneeqSlice.reducer,
}

export const store = configureStore({ reducer, middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(formSessionStorageMiddleware),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
