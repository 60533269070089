import React, { ReactElement, useState, useRef } from 'react'
import {
  Popover,
  Box,
  Typography,
  PopoverOrigin,
  SxProps,
  Theme,
} from '@mui/material'
import { Question, SuggestedQuestion } from './SuggestedQuestion'
import { ButtonSize } from '../../types'
import { HelpOutline } from '@mui/icons-material'
import { config } from '../../config'

export interface SuggestedQuestionsProps {
  /**
   * Placement for where the box will be anchored vertically relative to child component
   */
  vertical?: PopoverOrigin['vertical']

  /**
   * Placement for where the box will be anchored horizontally relative to child component
   */
  horizontal?: PopoverOrigin['horizontal']

  /**
   * Placement for where the box will be positioned relative to the anchor
   */
  transformOrigin?: PopoverOrigin

  /**
   * Title to display for popout
   */
  title?: string

  /**
   * Component that will trigger the popout
   */
  children?: ReactElement

  /**
   * Array of questions to display
   */
  questions: Question[]

  /**
   * Size of ask buttons
   */
  askButtonSize?: ButtonSize

  /**
   *  Stylings for Popup Box
   */
  popupStyles?: SxProps<Theme>

  /**
   *  Stylings for Suggested Questions Paper
   */
  suggestedQuestionStyles?: SxProps<Theme>
}

export const SuggestedQuestions = (props: SuggestedQuestionsProps) => {
  const {
    vertical,
    horizontal,
    children,
    title,
    questions,
    transformOrigin,
    askButtonSize,
    popupStyles,
    suggestedQuestionStyles,
  } = props
  const { suggestedQuestionsTimeout } = config.timeouts
  const [open, setOpen] = useState(false)
  const [timer, setTimer] = useState<number>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const ref = useRef<null | HTMLElement>(null)

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    clearTimeout(timer)
    setAnchorEl(ref.current)
    setOpen(true)
  }

  const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
    setTimer(window.setTimeout(() => setOpen(false), suggestedQuestionsTimeout))
  }

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          display: 'inline-flex',
          alignItems: 'center',
        }}
        onMouseEnter={handleMouseEnter}
      >
        <Box sx={{ display: 'flex' }} ref={ref} onMouseLeave={handleMouseLeave}>
          {children || <HelpOutline fontSize="medium" />}
        </Box>
        <Popover
          open={open}
          sx={{ zIndex: 1000000 }}
          onClose={() => setOpen(false)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: vertical || 'top',
            horizontal: horizontal || 'right',
          }}
          transformOrigin={transformOrigin}
        >
          <Box
            sx={{
              p: 2,
              gap: 2,
              bgcolor: 'background.paper',
              minWidth: '200px',
              maxWidth: '400px',
              display: 'flex',
              flexDirection: 'column',
              ...popupStyles,
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Typography>
              <strong>{title}</strong>
            </Typography>

            {questions.map((question) => {
              return (
                <SuggestedQuestion
                  question={question}
                  key={question.title}
                  askButtonSize={askButtonSize}
                  suggestedQuestionStyles={suggestedQuestionStyles}
                />
              )
            })}
          </Box>
        </Popover>
      </Box>
    </>
  )
}
