import { createSlice } from '@reduxjs/toolkit'

export interface ProgressBarSlice {
  value: number
}

const initialState: ProgressBarSlice = {
  value: 0,
}

export const progressBarSlice = createSlice({
  name: 'progressBar',
  initialState,
  reducers: {
    setProgress: (state, action) => {
      state.value = action.payload
    },
  },
})

