/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import * as Yup from 'yup'
import { Field, Form, FormikProvider, useFormik } from 'formik'
import { TextField } from '../components/inputs/TextField'
import { Box, Typography, InputAdornment, Button } from '@mui/material'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { formFill, horizontalFlex, unitToggle, calculatorInputStyles, calculatorLabelStyles, whiteBox } from '../util/sx'
import { colors } from '../theme';
import { calculateMortgageResults } from '../util/calculations'
import { UnitOptions, Units, MortgageCalculatorValues, MortgageResult } from '../schema/mortgageCalculator'

interface MortgageCalculatorProps {
    formId: string,
    setResults: React.Dispatch<React.SetStateAction<MortgageResult[][]>>,
    setShowResults: React.Dispatch<React.SetStateAction<boolean>>,
    units: Units,
    setUnits: React.Dispatch<React.SetStateAction<Units>>,
    calcInputs: MortgageCalculatorValues,
    setCalcInputs: React.Dispatch<React.SetStateAction<MortgageCalculatorValues>>
}

interface UnitAdornmentProps {
    unitList: UnitOptions[]
    unitKey: string
}

interface StaticEndAdornmentProps {
    unit: UnitOptions
}

export const MortgageCalculatorForm = (props: MortgageCalculatorProps) => {
    const { formId, setShowResults, setResults, units, setUnits, calcInputs, setCalcInputs } = props
    const { t } = useTranslation()
    const dollarPercentUnits = [UnitOptions.Dollars, UnitOptions.Percentage]
    const periodUnitList = [UnitOptions.Year, UnitOptions.Month]

    const initialValues: MortgageCalculatorValues = {
        propertyValue: calcInputs?.propertyValue,
        downPayment: calcInputs?.downPayment,
        mortgageAmount: calcInputs?.mortgageAmount,
        interestRate: calcInputs?.interestRate,
        amortizationPeriod: calcInputs?.amortizationPeriod,
        pmi: calcInputs?.pmi,
        propertyTax: calcInputs?.propertyTax,
        homeInsurance: calcInputs?.homeInsurance,
        hoaFees: calcInputs?.hoaFees,
        units: units
    }

    const validationSchema = Yup.object().shape({
        propertyValue: Yup.number()
            .typeError(t('mustBeNumber'))
            .min(0, t('minimumNumber'))
            .required(t('required')),
        downPayment: Yup.number()
            .typeError(t('mustBeNumber'))
            .min(0, t('minimumNumber'))
            .when('downPaymentUnit', {
            is: (downPaymentUnit: string) => downPaymentUnit === UnitOptions.Percentage,
                then: Yup.number().typeError(t('mustBeNumber')).max(100, t('forms.propertyFinancing.maximumValue')),
                otherwise: Yup.number().typeError(t('mustBeNumber'))
            })
            .required(t('required')),
        mortgageAmount: Yup.number()
            .typeError(t('mustBeNumber'))
            .min(0, t('minimumNumber'))
            .required(t('required')),
        interestRate: Yup.number()
            .typeError(t('mustBeNumber'))
            .min(0, t('minimumNumber'))
            .max(100, t('forms.propertyFinancing.maximumValue'))
            .required(t('required')),
        amortizationPeriod: Yup.number()
            .typeError(t('mustBeNumber'))
            .min(0, t('minimumNumber'))
            .required(t('required')),
        pmi: Yup.number()
            .typeError(t('mustBeNumber'))
            .min(0, t('minimumNumber'))
            .when('downPaymentUnit', {
            is: (pmiUnit: string) => pmiUnit === UnitOptions.Percentage,
                then: Yup.number().typeError(t('mustBeNumber')).max(100, t('forms.propertyFinancing.maximumValue')),
                otherwise: Yup.number().typeError(t('mustBeNumber'))
            })
            .required(t('required')),
        propertyTax: Yup.number()
            .typeError(t('mustBeNumber'))
            .min(0, t('minimumNumber'))
            .when('downPaymentUnit', {
            is: (propertyTaxUnit: string) => propertyTaxUnit === UnitOptions.Percentage,
                then: Yup.number().typeError(t('mustBeNumber')).max(100, t('forms.propertyFinancing.maximumValue')),
                otherwise: Yup.number().typeError(t('mustBeNumber'))
            })
            .required(t('required')),
        homeInsurance: Yup.number()
            .typeError(t('mustBeNumber'))
            .min(0, t('minimumNumber'))
            .when('downPaymentUnit', {
            is: (propertyTaxUnit: string) => propertyTaxUnit === UnitOptions.Percentage,
                then: Yup.number().typeError(t('mustBeNumber')).max(100, t('forms.propertyFinancing.maximumValue')),
                otherwise: Yup.number().typeError(t('mustBeNumber'))
            })
            .required(t('required')),
        hoaFees: Yup.number()
            .typeError(t('mustBeNumber'))
            .min(0, t('minimumNumber'))
            .required(t('required')),
    })

    const onSubmit = (values: MortgageCalculatorValues) => {
        setCalcInputs(values)
        const calculatedResults = calculateMortgageResults(values, units)
        setResults(calculatedResults)
        setShowResults(true)
    }

    const formik = useFormik({ initialValues, validationSchema, onSubmit })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleUnitSelect = (event: any, field: string) => {
        const value = event.target.value
        const updateUnits = { ...formik.values.units, [field]: value } as Units
        setUnits(updateUnits)
        formik.values.units = updateUnits
    }

    function genUnitAdornment(props: UnitAdornmentProps) {
        const { unitList, unitKey } = props

        return (
            <InputAdornment position="end">
                {unitList.map(option => {
                  const isSelected = option === units[unitKey as keyof Units]
                  return(
                    <Button
                      key={option}
                      value={option}
                      sx={{ ...unitToggle, color: isSelected? colors.white : 'inherit' }}
                      onClick={e => handleUnitSelect(e, unitKey)}
                      variant={isSelected ? 'contained' : 'plain'}
                    >
                      <Typography sx={{pointerEvents: 'none', textTransform: 'capitalize'}}>{option}</Typography>
                    </Button>
                  )
                })}
            </InputAdornment>
        )
    }

    function genStaticEndAdornment(props: StaticEndAdornmentProps) {
        const { unit } = props
        return (
            <InputAdornment position='end'>
                <Button sx={{ ...unitToggle }} variant='contained'>
                    {unit}
                </Button>
            </InputAdornment>
        )
    }

    function genStartAdornment(key: string) {
        const unit = units[key as keyof Units]
        return unit === UnitOptions.Dollars ? <InputAdornment position="start">{unit}</InputAdornment> : <></>
    }

    return (
        <FormikProvider value={formik}>
            <Form id={formId} style={{ width: '100%' }}>
                <Box sx={{ ...formFill, ...whiteBox }}>
                    <Typography variant="h1" sx={{ p: 9 }}>{t('forms.mortgageCalculator.title')}</Typography>
                    <Box sx={horizontalFlex}>
                        <Field
                            component={TextField}
                            name='propertyValue'
                            label={t(`forms.mortgageCalculator.propertyValue`)}
                            inputStyles={calculatorInputStyles}
                            labelStyles={calculatorLabelStyles}
                            placeholder={t('numberPlaceholder')}
                            startAdornment={<InputAdornment position="start">{UnitOptions.Dollars}</InputAdornment>}
                            endAdornment={genStaticEndAdornment({unit: UnitOptions.Dollars})}
                            type="text"
                        />
                        <Field
                            component={TextField}
                            name='downPayment'
                            label={t(`downPayment`)}
                            inputStyles={calculatorInputStyles}
                            labelStyles={calculatorLabelStyles}
                            placeholder={t('numberPlaceholder')}
                            startAdornment={genStartAdornment('downPaymentUnit')}
                            endAdornment={genUnitAdornment({unitList: dollarPercentUnits, unitKey: 'downPaymentUnit'})}
                            type="text"
                        />
                    </Box>
                    <Box sx={horizontalFlex}>
                        <Field
                            component={TextField}
                            name='mortgageAmount'
                            label={t(`mortgageAmount`)}
                            inputStyles={calculatorInputStyles}
                            labelStyles={calculatorLabelStyles}
                            placeholder={t('numberPlaceholder')}
                            startAdornment={<InputAdornment position="start">{UnitOptions.Dollars}</InputAdornment>}
                            endAdornment={genStaticEndAdornment({unit: UnitOptions.Dollars})}
                            type="text"
                        />
                        <Field
                            component={TextField}
                            name='interestRate'
                            label={t(`forms.mortgageCalculator.interestRate`)}
                            inputStyles={calculatorInputStyles}
                            labelStyles={calculatorLabelStyles}
                            placeholder={t('numberPlaceholder')}
                            endAdornment={genStaticEndAdornment({unit: UnitOptions.Percentage})}
                            type="text"
                        />
                    </Box>
                    <Box sx={horizontalFlex}>
                        <Field
                            component={TextField}
                            name='amortizationPeriod'
                            label={t(`forms.mortgageCalculator.amortizationPeriod`)}
                            inputStyles={calculatorInputStyles}
                            labelStyles={calculatorLabelStyles}
                            placeholder={t('numberPlaceholder')}
                            endAdornment={genUnitAdornment({unitList: periodUnitList, unitKey: 'amortizationPeriodUnit'})}
                            type="text"
                        />
                        <Field
                            component={TextField}
                            name='pmi'
                            label={t(`forms.mortgageCalculator.pmi`)}
                            inputStyles={calculatorInputStyles}
                            labelStyles={calculatorLabelStyles}
                            placeholder={t('numberPlaceholder')}
                            startAdornment={genStartAdornment('pmiUnit')}
                            endAdornment={genUnitAdornment({unitList: dollarPercentUnits, unitKey: 'pmiUnit'})}
                            type="text"
                        />
                    </Box>
                    <Typography variant="h1" sx={{ p: 9 }}>{t('forms.mortgageCalculator.homeOwnerExpenses')}</Typography>
                    <Box sx={horizontalFlex}>
                        <Field
                            component={TextField}
                            name='propertyTax'
                            label={t(`forms.mortgageCalculator.propertyTax`)}
                            inputStyles={calculatorInputStyles}
                            labelStyles={calculatorLabelStyles}
                            placeholder={t('numberPlaceholder')}
                            startAdornment={genStartAdornment('propertyTaxUnit')}
                            endAdornment={genUnitAdornment({unitList: dollarPercentUnits, unitKey: 'propertyTaxUnit'})}
                            type="text"
                        />
                        <Field
                            component={TextField}
                            name='homeInsurance'
                            label={t(`forms.mortgageCalculator.homeInsurance`)}
                            inputStyles={calculatorInputStyles}
                            labelStyles={calculatorLabelStyles}
                            placeholder={t('numberPlaceholder')}
                            startAdornment={genStartAdornment('homeInsuranceUnit')}
                            endAdornment={genUnitAdornment({unitList: dollarPercentUnits, unitKey: 'homeInsuranceUnit'})}
                            type="text"
                        />
                    </Box>
                    <Box sx={horizontalFlex}>
                        <Field
                            component={TextField}
                            name='hoaFees'
                            label={t(`forms.mortgageCalculator.hoaFees`)}
                            inputStyles={{ ...calculatorInputStyles, maxWidth: '230px'}}
                            labelStyles={calculatorLabelStyles}
                            placeholder={t('numberPlaceholder')}
                            startAdornment={<InputAdornment position="start">{UnitOptions.Dollars}</InputAdornment>}
                            endAdornment={genStaticEndAdornment({unit: UnitOptions.Dollars})}
                            type="text"
                        />
                    </Box>
                </Box>
            </Form>
        </FormikProvider>
    )
}
