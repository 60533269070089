import React from 'react'
import { TextField } from '../components/inputs/TextField'
import * as Yup from 'yup'
import { Field, Formik, Form } from 'formik'
import { getFormValue, setForm } from '../store/form'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { useNavigate } from 'react-router-dom'
import { HOME_INFORMATION } from '../routes'
import { formFill } from '../util/sx'
import { Box } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { useUneeqAvatar } from '../hooks/uneeq'
import { FormErrorListener } from '../components/form/FormErrorListener'

interface PersonalDetailsFormProps {
  formId: string
}

export interface PersonalDetailsFormValues {
  firstName?: string
  lastName?: string
  email?: string
}

export const personalDetailsKey = 'personalDetails'

export const PersonalDetailsForm = (props: PersonalDetailsFormProps) => {
  const { formId } = props
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { speakText } = useUneeqAvatar()
  const formData = useAppSelector(
    getFormValue<PersonalDetailsFormValues>(personalDetailsKey)
  )

  const initialValues: PersonalDetailsFormValues = {
    firstName: formData?.firstName,
    lastName: formData?.lastName,
    email: formData?.email,
  }

  const validationSchema = Yup.object({
    firstName: Yup.string().required(t('required')),
    lastName: Yup.string().required(t('required')),
    email: Yup.string().email().required(t('required')),
  })

  const onSubmit = (values: PersonalDetailsFormValues) => {
    dispatch(setForm({ formKey: personalDetailsKey, values }))
    navigate(HOME_INFORMATION)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <>
        <FormErrorListener onSubmitError={() => speakText(t('formError'))} />
        <Form id={formId} style={{ width: '100%' }}>
          <Box sx={formFill}>
            <Field
              component={TextField}
              name="firstName"
              label={t('forms.firstName.firstNameLabel')}
              placeholder={t('typeHere')}
            />
            <Field
              component={TextField}
              name="lastName"
              label={t('forms.firstName.lastNameLabel')}
              placeholder={t('typeHere')}
            />
            <Field
              component={TextField}
              name="email"
              label={t('forms.firstName.emailLabel')}
              placeholder={t('typeHere')}
            />
          </Box>
        </Form>
      </>
    </Formik>
  )
}
