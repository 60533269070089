/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Field, getIn } from 'formik'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { Box, Button, InputAdornment } from '@mui/material'
import { TextField } from '../../components/inputs/TextField'
import { Toggle } from '../../components/inputs/Toggle'
import { SubFormProps } from '../../types'
import { useCreateSuggestedQuestions } from '../../hooks/suggestedQuestions'
import { Autocomplete } from '../../components/inputs/Autocomplete'

export const HomeSubForm = (props: SubFormProps) => {
  const {
    fieldPath,
    onDelete,
    form: { values },
    deleteButtonText,
  } = props
  const { t } = useTranslation()
  const toggleOptions = [t('sell'), t('rent')]
  const dropdownOptions = [t('primary'), t('rental')]
  const homeUsefieldPath = `${fieldPath}.homeUse`
  const homeUseValue = getIn(values, homeUsefieldPath)
  const rentOrSellfieldPath = `${fieldPath}.rentOrSell`
  const rentOrSellValue = getIn(values, rentOrSellfieldPath)
  const questions = useCreateSuggestedQuestions('housePayments')

  const isRental = homeUseValue === t('rental')
  const showRental =
    rentOrSellValue === t('rent') || homeUseValue === t('rental')

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box>
        <Field
          component={Autocomplete}
          name={homeUsefieldPath}
          label={t('forms.homeDetails.homeUseLabel')}
          placeholder={t('chooseOption')}
          options={dropdownOptions}
        />
      </Box>
      <Box>
        <Field
          name={`${fieldPath}.monthlyHomePayment`}
          component={TextField}
          label={t('forms.homeDetails.monthlyHomePaymentLabel')}
          placeholder={t('numberPlaceholder')}
          suggestedQuestionsProps={{ questions }}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          type="text"
        />
      </Box>

      <Box>
        {!isRental && (
          <Field
            name={rentOrSellfieldPath}
            component={Toggle}
            toggleOptions={toggleOptions.map((option) => ({
              id: option,
              text: option,
            }))}
            label={t('forms.homeDetails.rentOrSellLabel')}
            buttonSize="half"
          />
        )}
      </Box>
      <Box>
        {showRental && (
          <Field
            name={`${fieldPath}.monthlyRentalIncome`}
            component={TextField}
            label={
              isRental
                ? t('forms.homeDetails.monthlyRentalIncomeLabel')
                : t('forms.homeDetails.monthlyRentalIncomeLabelNonRental')
            }
            placeholder={t('numberPlaceholder')}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            type="text"
          />
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={onDelete} variant="contained" color="error">
          {deleteButtonText}
        </Button>
      </Box>
    </Box>
  )
}
