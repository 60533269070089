import React from 'react'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { selectSessionState } from '../../store/uneeq'
import { SessionState } from '../../types/uneeq'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { useAppSelector } from '../../hooks/hooks'

interface StartSessionButtonProps extends LoadingButtonProps {
  /**
   * Button ready control
   */
  ready?: boolean
}

export const StartSessionButton = (props: StartSessionButtonProps) => {
  const { ready, ...restProps } = props
  const sessionState = useAppSelector(selectSessionState)
  const { t } = useTranslation()

  const isSDKReady = sessionState === SessionState.SDK_READY
  const _ready = isSDKReady || ready

  return (
    <LoadingButton
      disabled={!_ready}
      loading={!_ready}
      type="submit"
      {...restProps}
    >
      {t('startSessionLabel')}
    </LoadingButton>
  )
}
