import { createSlice } from '@reduxjs/toolkit'
import { SessionState } from '../../types/uneeq'

export interface FormSlice {
  sessionState: SessionState
  sentQuestion?: string
  avatarSpeech?: object
  sessionIdJwt?: string
  avatarSpeaking: boolean
}

const initialState: FormSlice = {
  sessionState: SessionState.INITIAL,
  avatarSpeaking: false,
}

export const uneeqSlice = createSlice({
  name: 'uneeqSlice',
  initialState,
  reducers: {
    setSessionState: (state, action) => {
      state.sessionState = action.payload
    },
    setSentQuestion: (state, action) => {
      state.sentQuestion = action.payload
    },
    setAvatarSpeech: (state, action) => {
      state.avatarSpeech = action.payload
    },
    setSessionIdJwt: (state, action) => {
      state.sessionIdJwt = action.payload
    },
    setAvatarSpeaking: (state, action) => {
      state.avatarSpeaking = action.payload
    },
  },
})
