import React from 'react'
import { LinearProgress as MuiLinearProgress, LinearProgressProps as MuiLinearProgressProps, LinearProgressPropsColorOverrides } from '@mui/material'
import { OverridableStringUnion } from '@mui/types';
import { getProgressBar } from '../../store/progressBar'
import { useAppSelector } from '../../hooks/hooks';

interface LinearProgressProps extends MuiLinearProgressProps {
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   * @default 'primary'
   */
  color?: OverridableStringUnion<
    'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit',
    LinearProgressPropsColorOverrides
  >;

  /**
   * Progress of bar
   */
  value?: number
}

export const LinearProgress = (props: LinearProgressProps) => {
  // Change to Redux when setup with proper hooks
  const { value, ...restProps } = props
  const progress = useAppSelector(getProgressBar)

  return (
    <MuiLinearProgress value={value || progress} {...restProps} />
  )
}
