import React, { useState } from 'react'
import { Box, Button, Typography, Stack } from '@mui/material'
import { flexFill, formFill, calculatorPageLayout, whiteBox } from '../util/sx'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { useNavigate } from 'react-router-dom'
import { FINANCING } from '../routes'
import { MortgageCalculatorForm } from '../forms/MortgageCalculatorForm'
import {
  UnitOptions,
  Units,
  MortgageCalculatorValues,
  MortgageResult,
} from '../schema/mortgageCalculator'
import { convertButtonSize } from '../util/button'
import { colors } from '../theme'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface MortgageCalculatorInputProps {
  formId: string
  setResults: React.Dispatch<React.SetStateAction<MortgageResult[][]>>
  setShowResults: React.Dispatch<React.SetStateAction<boolean>>
  units: Units
  setUnits: React.Dispatch<React.SetStateAction<Units>>
  calcInputs: MortgageCalculatorValues
  setCalcInputs: React.Dispatch<React.SetStateAction<MortgageCalculatorValues>>
}

interface MortgageCalculatorResultsProps {
  setShowResults: React.Dispatch<React.SetStateAction<boolean>>
  results: MortgageResult[][]
}

const initialUnits: Units = {
  downPaymentUnit: UnitOptions.Percentage,
  pmiUnit: UnitOptions.Percentage,
  propertyTaxUnit: UnitOptions.Percentage,
  homeInsuranceUnit: UnitOptions.Percentage,
  amortizationPeriodUnit: UnitOptions.Month,
}

const initialInputs: MortgageCalculatorValues = {
  propertyValue: undefined,
  downPayment: undefined,
  mortgageAmount: undefined,
  interestRate: undefined,
  amortizationPeriod: undefined,
  pmi: undefined,
  propertyTax: undefined,
  homeInsurance: undefined,
  hoaFees: undefined,
  units: initialUnits,
}

export const MortgageCalculatorPage = () => {
  const formId = 'mortageCalculatorFrom'
  const [showResults, setShowResults] = useState(false)
  const [results, setResults] = useState<MortgageResult[][]>([])
  const [units, setUnits] = useState(initialUnits)
  const [calcInputs, setCalcInputs] = useState(initialInputs)

  return (
    <Box sx={{ ...flexFill, alignItems: 'center' }}>
      {showResults ? (
        <MortgageCalculatorResults
          setShowResults={setShowResults}
          results={results}
        />
      ) : (
        <MortgageCalculatorInput
          formId={formId}
          setShowResults={setShowResults}
          setResults={setResults}
          units={units}
          setUnits={setUnits}
          calcInputs={calcInputs}
          setCalcInputs={setCalcInputs}
        />
      )}
    </Box>
  )
}

const MortgageCalculatorInput = (props: MortgageCalculatorInputProps) => {
  const {
    formId,
    setShowResults,
    setResults,
    units,
    setUnits,
    calcInputs,
    setCalcInputs,
  } = props
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleBackToApplication = () => {
    navigate(FINANCING)
  }

  return (
    <Box sx={calculatorPageLayout}>
      <Button
        onClick={handleBackToApplication}
        variant="contained"
        sx={{
            justifyContent:"flex-start",
            height: convertButtonSize('full')
        }}
        >
            <ArrowBackIcon />
            {t('forms.mortgageCalculator.continueWithApplication')}
      </Button>
      <Box sx={{ display: 'flex', overflowY: 'scroll', overflowX: 'hidden' }}>
        <MortgageCalculatorForm
          formId={formId}
          setShowResults={setShowResults}
          setResults={setResults}
          units={units}
          setUnits={setUnits}
          calcInputs={calcInputs}
          setCalcInputs={setCalcInputs}
        />
      </Box>
      <Box>
        <Button
          variant="contained"
          sx={{ height: convertButtonSize('full') }}
          form={formId}
          type="submit"
        >
          {t('calculate')}
        </Button>
      </Box>
    </Box>
  )
}

const MortgageCalculatorResults = (props: MortgageCalculatorResultsProps) => {
  const { setShowResults, results } = props
  const { t } = useTranslation()
  const totalPayment: number = results
    .flat()
    .reduce((total, { value }) => total + value, 0)

  const handleBackToCalculator = () => {
    setShowResults(false)
  }

  const resultsBorder = `1px solid ${colors.lightGrey}`

  return (
    <Box sx={calculatorPageLayout}>
      <Button
        onClick={handleBackToCalculator}
        variant="contained"
        sx={{
            justifyContent:"flex-start",
            height: convertButtonSize('full')
        }}
        >
            <ArrowBackIcon />
            {t('forms.mortgageCalculator.backToCalculator')}
      </Button>
      <Typography variant="h1">{t('results')}</Typography>
      <Box
        sx={{
          ...formFill,
          ...whiteBox,
          justifyContent: 'center',
          px: 2.5,
          py: 4,
        }}
      >
        {results.map((row, index) => (
          <Stack direction="row" key={index} sx={{ width: 1 }}>
            {row.map(({ key, value }, rowIndex) =>
              key ? (
                <Stack
                  direction="column"
                  key={key}
                  flex="1"
                  justifyContent="space-between"
                  sx={{
                    height: 1,
                    px: 4,
                    borderRight: rowIndex < row.length - 1 ? resultsBorder : null,
                    borderBottom:
                      index < results.length - 1 ? resultsBorder : null,
                  }}
                >
                  <Stack direction="column" justifyContent="space-between" sx={{ py: 2, height: 1 }}>
                    <Typography variant="subtitle1">
                      {t(`forms.mortgageCalculator.${key}`)}
                    </Typography>
                    <Typography>{`$${Math.round(value)}`}</Typography>
                  </Stack>
                </Stack>
              ) : (
                <Stack direction="column" flex="1" sx={{ px: 4, py: 2 }} />
              )
            )}
          </Stack>
        ))}
      </Box>
      <Box sx={{ ...formFill, ...whiteBox, alignItems: 'center', py: 3 }}>
        <Typography variant="subtitle1">
          {t('forms.mortgageCalculator.totalMonthlyPayment')}
        </Typography>
        <Typography variant='h2'>{`$${Math.round(
          totalPayment
        )}`}</Typography>
      </Box>
    </Box>
  )
}
