import { progressBarSlice } from './slice'
import { progressRoutes } from '../../routes'
import { matchPath } from 'react-router-dom'
import { Dispatch } from '@reduxjs/toolkit'
import { RootState } from '../store'

export const { setProgress } = progressBarSlice.actions

export const setProgressValueByRoute =
  () => (dispatch: Dispatch, getState: () => RootState) => {
    const pathName = window.location.pathname

    const index = progressRoutes.findIndex((route) =>
      matchPath(route.path, pathName)
    )

    const previousProgress = getState().progressBar.value
    const progress =
      index >= 0
        ? index * (100 / (progressRoutes.length - 1))
        : previousProgress
    dispatch(setProgress(progress))
  }
