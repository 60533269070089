import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { flexFill } from '../util/sx'
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { useNavigate } from 'react-router-dom'
import { FINAL_PAGE } from '../routes'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { setProgressValueByRoute } from '../store/progressBar'
import { useUneeqAvatar } from '../hooks/uneeq'
import { getForm, setForm } from '../store/form'
import { calculateDebtToIncomeRatio, getInterestRate } from '../util/calculations'

export const formKey = 'loadingFinalPage'

export interface LoadingFinalPageFormValues {
  debtToIncomeRatio?: number
}

export const LoadingFinalPage = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const form = useAppSelector(getForm)
  const { sessionIdJwt } = useUneeqAvatar(t('pages.loadingFinalPage.avatarIntro'))
  const [fact, setFact] = useState<string>("")

  useEffect(() => {
    if (!form || !sessionIdJwt) {
      return
    }

    (async () => {
      const debtToIncomeRatio = await calculateDebtToIncomeRatio(form, t('yearly'), getInterestRate, sessionIdJwt)
      dispatch(setForm({ formKey, values: { debtToIncomeRatio } }))
      navigate(FINAL_PAGE)
    })();
  }, [form, t, sessionIdJwt, dispatch, navigate])

  useEffect(() => {
    dispatch(setProgressValueByRoute())
  }, [dispatch])

  useEffect(() => {
    setFact(t('pages.loadingFinalPage.facts'));
  }, [t, setFact])

  return (
    <Box
      sx={{
        ...flexFill,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box>
        <Typography sx={{ fontWeight: 'bold' }}>
          {t('pages.loadingFinalPage.didYouKnow')}
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography>{fact}</Typography>
      </Box>
      <Box sx={{ p: 4 }}>
        <CircularProgress size={85} />
      </Box>
    </Box>
  )
}
