export interface MortgageCalculatorValues {
    propertyValue?: number,
    downPayment?: number,
    mortgageAmount?: number,
    interestRate?: number,
    amortizationPeriod?: number,
    pmi?: number,
    propertyTax?: number,
    homeInsurance?: number,
    hoaFees?: number,
    units?: Units
}

export interface MortgageResult {
    key?: string
    value: number
}

export interface Units {
    downPaymentUnit: UnitOptions,
    pmiUnit: UnitOptions,
    propertyTaxUnit: UnitOptions,
    homeInsuranceUnit: UnitOptions,
    amortizationPeriodUnit: UnitOptions
}

export enum UnitOptions {
    Dollars = '$',
    Percentage = '%',
    Month = 'Mo',
    Year = 'Yr'
}
