import * as R from 'ramda'

export const config = {
  interestRateAPI: {
    url: () => getEnv('REACT_APP_INTEREST_RATE_URL'),
    productKey: () => getEnv('REACT_APP_INTEREST_RATE_PRODUCT_KEY'),
  },
  defaultRates: {
    interestRate: () => 0.055,
    rentalIncomeRateReduction: () => 0.75,
    statePropertyTaxRate: (state = '') =>
      R.cond([
        [R.equals('TX'), R.always(0.025)],
        [R.equals('NJ'), R.always(0.025)],
        [R.T, R.always(0.0175)],
      ])(state),
    mortgageInsuranceThreshold: () => 0.2,
    mortgageInsurance: () => 0.0035,
  },
  uneeq: {
    shareId: () => getEnv('REACT_APP_UNEEQ_PERSONA_SHARE_ID'),
    baseUrl: () => getEnv('REACT_APP_UNEEQ_BASE_URL'),
    zapierUrl: process.env.REACT_APP_ZAPIER_URL || "",
    shouldInterruptSpeechBetweenPages: () =>
      process.env.REACT_APP_INTERRUPT_SPEECH_BETWEEN_PAGES || false,
  },
  timeouts: {
    idleDialogTimeout: 60,
    idleTimeout: 1000 * 60 * 5,
    suggestedQuestionsTimeout: 1000,
  },
}

const getEnv = (name: string): string => {
  const variable = process.env[name]
  if (!variable) {
    throw new Error(`Environment variable missing ${name} in config`)
  }
  return variable
}
