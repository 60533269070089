/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
  FormHelperText,
  FormHelperTextProps,
  SxProps,
  Theme,
} from '@mui/material'
import { FormikErrors, FormikTouched, getIn } from 'formik'

interface ErrorHelperProps extends FormHelperTextProps {
  /**
   * Formik errors object from the form
   */
  errors: FormikErrors<any>

  /**
   * Formik touched object from the form
   */
  touched: FormikTouched<any>

  /**
   * Formik form name for the input
   */
  name: string

  /**
   * Styles to apply to the container around
   */
  errorHelperStyles?: SxProps<Theme>
}

export const ErrorHelper = (props: ErrorHelperProps) => {
  const { name, touched, errors, errorHelperStyles, ...otherProps } = props
  const error = getIn(errors, name)
  const touch = getIn(touched, name)

  return (
    <FormHelperText
      id={`${name}-error`}
      error
      sx={{ minHeight: 20, mt: 0, mb: 1, ...errorHelperStyles }}
      {...otherProps}
    >
      {touch && error}
    </FormHelperText>
  )
}
