import React from "react";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import openCalculatorImage from '../../assets/openCalculatorImg.png'
import { Button, Typography } from "@mui/material";
import { useTranslation } from '@elementx-ai/uneeq-sdk-react'
import { colors } from '../../theme'
import { convertButtonSize } from '../../util/button'

const openCalculatorButtonStyles = {
    backgroundColor: colors.white,
    color: colors.secondaryText,
    width: '100%',
    maxWidth: '400px',
    textTransform: 'capitalize',
    height: convertButtonSize('large'),
    "&:hover": {
        backgroundColor: colors.secondary
    }
}

interface OpenCalculatorButtonProps {
    handleCalculatorOpen: () => void
}

export const OpenCalculatorButton = (props: OpenCalculatorButtonProps) => {
    const { handleCalculatorOpen } = props
    const { t } = useTranslation()
    return (
        <Button
            sx={openCalculatorButtonStyles}
            onClick={handleCalculatorOpen}
        >
            <Typography>
                {t('forms.propertyFinancing.viewMortgageCalculator')}
            </Typography>
            <ArrowForwardIosIcon fontSize='inherit' sx={{ ml: 2, mr: 12 }}/>
            <img src={openCalculatorImage} alt='OpenCalculatorImage' />
        </Button>
    )
}
